<template>
    <div class="customerOrder">
        <el-input placeholder="搜索商品关键词/SKU" size="small" v-model="searchVal" @clear="clearSearch"
                  class="search-box" clearable @keyup.enter.native="searchBtn">
            <i slot="prefix" class="el-input__icon el-icon-search" @click="searchBtn"></i>
        </el-input>
        <el-scrollbar class="goods-list">
            <div class="good-item" v-for="item in goodsList" :key="`goodsList_${item.id}`">
                <div class="good-img">
                    <img :src="item.good_images" alt="" @click="viewGoodsView(item)">
                </div>
                <div class="good-info">
                    <div class="line">
                        <div class="text-overflow-2 text" @click="viewGoodsView(item)">{{item.good_title}}</div>
                        <i class="right icon-gray iconfont copy-sku" style="margin-left: 30px" @click="clipSku(item.good_title)">&#xe636;</i>
                    </div>
                    <div class="line">
                        <div class="text">单价 <span class="red">&yen;{{item.sales_price}}</span></div>
                    </div>
                    <div class="line">
                        <div class="text">SKU: <span class="golden">{{item.good_sku}}</span> <i class="icon-gray iconfont copy-sku" style="margin-left: 10px" @click="clipSku(item.good_sku)">&#xe636;</i></div>
                        <div class="right" v-if="chatHistoryCurrent">
                            <el-link :underline="false" type="success" @click="sendBtn(item)"><i class="iconfont">&#xe764;</i>发送</el-link>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import { kefuGood, kefu_drillGood } from '@/utils/apis.js'
    import ClipboardJS from "clipboard";
    export default {
        props: {
            chatHistoryCurrent: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                searchVal: '',
                goodsList: [],
                isExamOrTrain: localStorage.getItem('examId'), // 有：考试， null：训练
                trainId: Number(this.$route.query.id) || null,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            clipSku(value) {
                let clipboard = new ClipboardJS('.copy-sku', {
                    text(elem) {
                        return value;
                    }
                });
                clipboard.on('success', (e) => {
                    this.$message({
                        type: 'success',
                        message: '复制成功',
                        duration: 1000,
                        onClose: () => {
                            clipboard.destroy();
                        }
                    });
                });
            },
            getList() {
                let params = {}
                if (this.searchVal) {
                    params.search = this.searchVal
                }
                if (this.isExamOrTrain) {
                    kefuGood(params).then((res) => {
                        this.goodsList = res.data
                    })
                } else {
                    params.id = this.trainId
                    kefu_drillGood(params).then((res) => {
                        this.goodsList = res.data
                    })
                }
            },
            clearSearch() {
                this.getList()
            },
            searchBtn() {
                if (this.searchVal === '') {
                    return this.$message.warning('请输入商品关键词/SKU')
                }
                this.getList()
            },
            // 发送商品
            sendBtn(item) {
                let tmp = {
                    score_operate: 3,
                    good_id: item.id,
                    goodInfo: item
                }
                let typeTmp = this.chatHistoryCurrent.stu_post_content[this.chatHistoryCurrent.stu_post_content.length - 1]
                if (typeTmp.type === 1) {
                    return this.$message.warning('当前题型为问答题，请输入答案')
                } else {
                    this.$emit('sendMessage', tmp)
                    this.$message.success('发送商品成功')
                }
            },
            //查看商品详情
            viewGoodsView(item) {
                let routeDate = this.$router.resolve({
                    path: '/goods/detail',
                    query:{
                        g_id: item.id,
                        type: 1
                    }
                })
                window.open(routeDate.href, '_blank')
            }
        }
    }
</script>

<style scoped lang="scss">
    .customerOrder {
        height: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .search-box {
        margin: 20px 15px;
        width: calc(100% - 30px);
    }
    .goods-list {
        height: 1%;
        flex: 1;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .good-item {
        display: flex;
        padding: 10px 15px;
        border-bottom: 1px solid #E5E5E5;
        &:first-child {
            border-top: 1px solid #E5E5E5;
        }
        .good-img {
            width: 80px;
            height: 80px;
            display: flex;
            background: red;
            cursor: pointer;
            img {
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .good-info {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .line {
                display: flex;
                .text {
                    width: 1%;
                    flex: 1;
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .icon-gray {
        color: #7D7D7D;
        cursor: pointer;
    }
    .red {
        color: #F61F03;
    }
    .golden {
        color: #B67F00;
    }
    .text-overflow-2 {
        cursor: pointer;
    }
</style>