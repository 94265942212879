import {
    termsSearch,
    getKefuInfo,
    kefuSubmit,
    kefuStatus,
    kefuHistorical,
    kefuSubmitEven,
    kefuDrill,
    kefu_drillStatus,
    kefu_drillSubmit,
    kefu_drillHistorical,
    kefu_drillSubmitEven,
    kefu_drillTrainingRecord
} from '@/utils/apis.js'
import clickoutside from '../../../../utils/clickoutside'
import Editor from '@tinymce/tinymce-vue'
import emoji from '../js/emoji'
import * as dayjs from 'dayjs'
import AnswerTimer from '../AnswerTimer';
import Phrase from '../Phrase'
import CustomerOrOrder from '../CustomerOrOrder'
import OrderCompensate from '../OrderCompensate'
import Goods from '../Goods'
import Coupons from '../Coupons'
import ScorePage from '../ScorePage'
import DialoguePage from '../DialoguePage'
import ClipboardJS from "clipboard";

export default {
    directives: {
        clickoutside
    },
    components: {
        AnswerTimer,
        Phrase,
        CustomerOrOrder,
        OrderCompensate,
        Goods,
        Coupons,
        ScorePage,
        DialoguePage,
        'tiny-editor': Editor,
    },
    data() {
        let that = this;
        return {
            moduleId: Number(this.$route.query.module_id) || null,
            isExamOrTrain: localStorage.getItem('examId'), // 有：考试， null：训练
            trainId: Number(this.$route.query.id) || null,
            course_id: Number(this.$route.query.course_id) || null,
            chapter: Number(this.$route.query.chapter) || null,
            node: Number(this.$route.query.node) || null,
            // 左一栏
            userInfo: { // √
                userName: localStorage.getItem('studentName') ? localStorage.getItem('studentName') : '',
                userIcon: localStorage.getItem('studentAvatar') ? localStorage.getItem('studentAvatar') : require('../../../../assets/images/user_img.png'),
            },
            robotNum: 0,
            robotHistoryNum: 0,
            // 左二栏
            tmpTrain: {}, // 中转考试的对象
            robotList: [], // 实时机器人tab1
            historyList: [], // 历史机器人tab2
            userTabCurrent: 1,
            addRobotTimer: null, // 添加机器人开始计时
            robotCurrentId: null, // 当前机器人id
            robotCurrentIndex: null, // 当前机器人index
            robotListType: 1, // 机器人1.在线机器人 2.历史机器人
            showOnlineOrSearch: 1, // 搜索用户
            searchUser: '',

            // 中间主要对话区域
            showScene: true, // 如果一开始进来没有训练 则显示false
            examStatus: 0, // 考试状态 类似userStatus 开始考试0，进行中1，考试结束2(后端的返回) 继续考试3，
            // 聊天富文本
            tiny_init2: {
                // base_url: '/tiny_mce/',
                icons: 'custom',
                language: 'zh_CN',
                menubar: false,
                height: 120,
                statusbar: false,
                toolbar: [
                    'formatting | customEmoji | customImage1',
                ],
                toolbar_groups: {
                    formatting: {
                        icon: 'customFont',
                        tooltip: 'Formatting',
                        items: 'fontsizeselect bold italic underline forecolor',
                    }
                },
                // auto_focus: true,
                plugins: 'customEmoji customImage1',
                content_css: './style/common.css',
                setup: (editor) => {
                    let debounceEvt = tinymce.util.Delay.debounce(() => {
                        let content = editor.getContent({ format: 'text' });
                        let pattern = /\/#.*/;
                        if (pattern.test(content)) {
                            let searchRes = /\/#.*/.exec(content);
                            let searchTmp = searchRes[0];
                            let params = {
                                level: 'one',
                                search: searchTmp
                            }
                            this.getPhrase(params)
                        } else {
                            this.showConnectWord = false
                        }
                    }, 500);
                    editor.on('input', debounceEvt);
                    tinymce.PluginManager.add('customEmoji', function addPlugin(editor) {
                        editor.ui.registry.addButton('customEmoji', {
                            icon: 'customEmoji',
                            onAction: () => {
                                that.showChatEmoji = !that.showChatEmoji
                            }
                        });
                    });
                    tinymce.PluginManager.add('customImage1', function addPlugin(editor) {
                        editor.ui.registry.addButton('customImage1', {
                            icon: 'customImage1',
                            onAction: () => {
                                let imageInput = document.createElement('input')
                                imageInput.setAttribute('type', 'file')
                                imageInput.setAttribute('accept', 'image/gif, image/jpg, image/png')
                                document.body.appendChild(imageInput)
                                // 模拟input点击事件
                                let evt = new MouseEvent("click", {
                                    bubbles: false,
                                    cancelable: true,
                                    view: window
                                });
                                imageInput.dispatchEvent(evt);
                                imageInput.addEventListener('change', e => {
                                    // 判断图片大小
                                    let sizeTmp = evt.target.files[0].size
                                    if (sizeTmp / 1024 / 1024 > 2) {
                                        that.$message.warning('图片上传大小应低于2M')
                                        return false
                                    }
                                    // 插入
                                    let formData = new FormData()
                                    formData.append('img', evt.target.files[0])
                                    // chatUpload(formData).then((res) => {
                                    //     editor.execCommand('mceInsertContent', false, `<img style='max-width: 260px; max-height: 140px' src='${res.data.src}'/>`)
                                    // })
                                })
                                // 图片插入完后，remove
                                imageInput.remove()
                            }
                        });
                    });
                    editor.on('keydown', (e) => {
                        if (this.sendMethods === 1) {
                            if (e.keyCode === 13) {
                                e.preventDefault()
                                this.sendMessage()
                            }
                        } else if (this.sendMethods === 2) {
                            if (e.keyCode === 13 && e.ctrlKey) {
                                e.preventDefault()
                                this.sendMessage()
                            }
                        }
                    });
                },
            },
            emojiList: emoji,
            defaultEmojiList: [
                {
                    code: 's31',
                    src: require('../emoji/s31.png'),
                },
                {
                    code: 's32',
                    src: require('../emoji/s32.png'),
                },
                {
                    code: 's33',
                    src: require('../emoji/s33.png'),
                },
                {
                    code: 's34',
                    src: require('../emoji/s34.png'),
                },
                {
                    code: 's35',
                    src: require('../emoji/s35.png'),
                },
                {
                    code: 's36',
                    src: require('../emoji/s36.png'),
                },
                {
                    code: 's37',
                    src: require('../emoji/s37.png'),
                },
                {
                    code: 's38',
                    src: require('../emoji/s38.png'),
                },
                {
                    code: 's39',
                    src: require('../emoji/s39.png'),
                },
                {
                    code: 's40',
                    src: require('../emoji/s40.png'),
                },
            ],
            chatContent: '',
            showChatEmoji: false,
            showSendMethodsBox: false,
            sendMethods: Number(localStorage.getItem('sendMethods')) ? Number(localStorage.getItem('sendMethods')) : 1,
            connectWordList: [],
            showConnectWord: false,
            currentConnectWord: 0,

            // 最后一栏
            robotOrCustomer: 1, // 1.机器人咨询；2.客服助手
            robotConsultStatus: 1, // 1:客户&订单, 2:订单直赔, 3:商品, 4:优惠券
            // 对话参考
            showDialogueScore: 1, // 显示对话参考或者培训成绩
            showDialogueContent: false, // false：显示对话参考缺省页   true：显示对话参考详情
            // 培训成绩
            scoreContent: {},
            examing: 0,
        }
    },
    watch: {
        sendMethods(val) {
            localStorage.setItem('sendMethods', val)
        },
    },
    beforeDestroy() {
        clearInterval(this.addRobotTimer)
        this.robotList.forEach(robotItem => {
            this.$refs[`robot_${robotItem.robot_id}`][0].endTime()
        })
    },
    computed: {
        btnStatus() {
            if (Number(this.examStatus) === 2) {
                return false;
            }
            if (this.examing === 0) {
                return true;
            }
            return false;
        },
        btnTxt() {
            if (this.examing === 1) {
                return this.isExamOrTrain ? '考试进行中' : '训练进行中';
            }
            if (this.examStatus === 2) {
                return this.isExamOrTrain ? '考试结束' : '训练结束';
            }
            if (this.examStatus === 0) {
                return this.isExamOrTrain ? '开始考试' : '开始训练';
            }
            if ([1,3].indexOf(this.examStatus) !== -1) {
                return this.isExamOrTrain ? '继续考试' : '继续训练';
            }
        },
        // 对话参考
        dialogueList() {
            if (this.chatHistoryCurrent === null) return []
            let chatHistoryList = JSON.parse(JSON.stringify(this.chatHistoryCurrent.stu_post_content))
            let arrTmp = []
            chatHistoryList.forEach(item => {
                let indexTmp = arrTmp.findIndex(item1 => item.currentRobotDataIndex === item1.currentRobotDataIndex)
                if (indexTmp === -1) {
                    arrTmp.unshift(item)
                } else {
                    arrTmp[indexTmp].isLastOrRight = item.isLastOrRight
                    arrTmp[indexTmp].stu_key_word = item.stu_key_word
                }
            })
            return arrTmp
        },
        // 当前历史机器人
        chatHistoryCurrent() {
            if (this.userTabCurrent === 1) {
                if (this.robotList.length === 0) return null
                return this.robotList[this.robotCurrentIndex]
            } else if (this.userTabCurrent === 2) {
                if (this.historyList.length === 0) return null
                if (this.robotList.length > 0) {
                    let itemTmp = this.historyList.find(item => {
                        return item.robot_id === this.robotList[this.robotCurrentIndex].robot_id
                    })
                    if (itemTmp) {
                        return itemTmp
                    }
                } else {
                    let itemTmp = this.historyList.find(item => {
                        return item.robot_id === this.robotCurrentId
                    })
                    if (itemTmp) {
                        return itemTmp
                    } else {
                        return null
                    }
                }
            }
            return null
        },
    },
    mounted() {
        this.getKefuStatus();
        // if (!this.isExamOrTrain) {
        //     this.getTrainResult()
        // }
    },
    methods: {
        clipSku(value) {
            let clipboard = new ClipboardJS('.copy-sku', {
                text(elem) {
                    return value;
                }
            });
            clipboard.on('success', (e) => {
                this.$message.success('复制成功');
            });
        },
        // 获取考试提交状态
        getKefuStatus() {
            if (this.isExamOrTrain) {
                kefuStatus().then((res) => {
                    this.examStatus = Number(res.data.status)
                    if (this.examStatus === 2 && this.examing === 1) {
                        this.examing = 0;
                        // this.$router.push('/student/exam');
                        this.$router.push('student/examing/onlineShopCustomerService/index');
                    }
                    if (this.examing === 1) {
                        this.init();
                    }
                })
            } else {
                let params = {
                    id: this.trainId,
                    course_id: this.course_id,
                    chapter: this.chapter,
                    node: this.node,
                }
                kefu_drillStatus(params).then((res) => {
                    this.examStatus = Number(res.data.status)
                    if (this.examing === 1) {
                        this.init();
                    }
                })
            }
        },
        // 开始考试 继续考试 结束考试
        beginExam(status) {
            this.examing = 1;
            let params = {
                status: status
            }
            if (this.isExamOrTrain) {
                kefuStatus(params).then((res) => {
                    this.getKefuStatus()
                })
            } else {
                params.id = this.trainId
                params.course_id = this.course_id
                params.chapter = this.chapter
                params.node = this.node
                kefu_drillStatus(params).then((res) => {
                    this.getKefuStatus()
                })
            }
        },
        hideChatEmoji() {
            this.showChatEmoji = false
        },
        // 搜索联想词
        getPhrase(params) {
            termsSearch(params).then((res) => {
                if (res.data.length > 0) {
                    let arrTmp = res.data
                    arrTmp.forEach((item) => {
                        if (item.img) {
                            item.html = item.content.replace(/<(?!img).*?>/g, '') + '[图片]'
                        } else {
                            item.html = item.content.replace(/<(?!img).*?>/g, '')
                        }
                        this.emojiList.forEach(item2 => {
                            if (item.html.includes(item2.code)) {
                                item.html = item.html.replace(new RegExp(item2.code, 'g'), `<img style='width: 24px; height: 24px' src='${item2.src}'/>`)
                            }
                        })
                    })
                    this.connectWordList = arrTmp
                    this.showConnectWord = true
                } else {
                    this.showConnectWord = false
                }
            })
        },
        // 添加选中短语到聊天框
        addPhraseToChat(item) {
            let ed = tinyMCE.get('tiny_item2');
            let text = null
            if (item.img) {
                text = `${item.content.replace(/<(?!img).*?>/g, '')}<img style='max-width: 260px; max-height: 140px' src='${item.img}'/>`
            } else {
                text = item.content.replace(/<(?!img).*?>/g, '')
            }
            this.emojiList.forEach(item2 => {
                if (text.includes(item2.code)) {
                    text = text.replace(new RegExp(item2.code, 'g'), `<img style='width: 24px; height: 24px' src='${item2.src}'/>`)
                }
            })
            let content = ed.getContent()
            let currentTmp = ed.getContent({ format: 'text' })
            let wordArr = currentTmp.split('/')
            content = content.replace(`/${wordArr[wordArr.length - 1]}`, text);
            ed.setContent(content);
            // ed.focus()
            this.showConnectWord = false
        },
        // 切换用户列表
        toggleUserTab(index, type) {
            this.historyList = []
            this.userTabCurrent = index
            this.robotListType = type
            if (index === 2) {
                this.getHistoryList()
            }
        },
        // init格式
        initFormat(examInfo) {
            let tmp = {
                id: examInfo.id, // 客服id  id
                name: examInfo.name, // 创建名称 kf_name
                banned_word: examInfo.banned_word, // 违禁词 forbidden
                repeat_ask_num: examInfo.repeat_ask_num, //重复提问次数 repetition_ask_num
                response_time: examInfo.response_time, //平均响应时间 avg_response_time
                scene: examInfo.scene, //客服场景 scene
                visit_interval: examInfo.visit_interval, //访问间隔 pv_interval
                wait_time: examInfo.wait_time, //等待时长 waiting_time
                robot_info: examInfo.robot_info, //机器人信息 robot_data
            }
            this.tmpTrain = tmp
            this.robotNum = examInfo.robot_num
            if (this.tmpTrain.robot_info.length > 0) {
                // if (this.tmpTrain.scene && this.showScene === false) {
                //     this.addRobot();
                // }
                if (this.tmpTrain.scene) {
                    if (this.showScene === false) {
                        this.addRobot();
                    }
                } else {
                    if (this.examing === 1) {
                        this.addRobot();
                    }
                }
                // this.addRobot();
                /*if (this.tmpTrain.scene) {
                    if (this.showScene === false) {
                        this.addRobot()
                    }
                } else {
                    if (this.examStatus === 2) {
                        this.addRobot()
                    }
                }*/
            } else {
                this.$message.warning('暂无机器人')
            }
        },
        // 获取单条客服数据和机器人详细
        init() {
            if (this.isExamOrTrain) {
                getKefuInfo().then((res) => {
                    this.initFormat(res.data)
                })
            } else {
                let params = {
                    id: this.trainId,
                    course_id: this.course_id,
                    chapter: this.chapter,
                    node: this.node,
                }
                kefuDrill(params).then((res) => {
                    this.initFormat(res.data)
                })
            }
        },
        // 开始添加机器人
        addRobot() {
            if (this.tmpTrain.hasOwnProperty('robot_info') && this.tmpTrain.robot_info.length > 0) {
                let tmp = this.getRobotItem(this.tmpTrain.robot_info[0], 0)
                this.robotList.push(tmp)
                this.$nextTick(() => {
                    this.$refs[`robot_${this.robotList[this.robotList.length - 1].robot_id}`][0].startTime()
                })
                this.robotCurrentId = this.robotList[0].robot_id
                this.robotCurrentIndex = 0
                let indexTmp = 1
                this.addRobotTimer = setInterval(() => {
                    if (indexTmp === this.tmpTrain.robot_info.length) {
                        clearInterval(this.addRobotTimer)
                        return
                    }
                    this.robotList.push(this.getRobotItem(this.tmpTrain.robot_info[indexTmp], indexTmp))
                    this.$nextTick(() => {
                        this.$refs[`robot_${this.robotList[this.robotList.length - 1].robot_id}`][0].startTime()
                    })
                    indexTmp++
                }, this.tmpTrain.visit_interval * 1000)
            } else {
                this.$message.warning('暂无机器人')
            }
        },
        // 调整每个机器人的数据
        getRobotItem(robotItem, indexTmp) {
            let arrTmp = robotItem.question.map((item1) => {
                let tmp = {
                    analysis: item1.analysis, // 解析 analysis
                    name: item1.name, // 考点 testing_name
                    question: item1.question, // 问题 question
                    score: item1.score, // 分值 score
                    score_operate: item1.score_operate, // 得分操作 key_word（问题类型是2时，对应的1：发送商品优惠券；2：申请直赔；3：发送商品；4：发送新建优惠券；5：发送核对订单）
                    type: item1.type, // 问题类型（1：问答题；2：操作题）
                }
                if (item1.type === 1) { // 问答题
                    tmp.no_score_keyword = item1.no_score_keyword // 不得分关键词 no_key_word
                    tmp.answer = item1.answer // 答案 model_answer
                    return tmp
                } else if (item1.type === 2) {
                    tmp.score_operate_value = item1.score_operate_value
                    if (item1.score_operate === 1) { // 发送商品优惠券
                        tmp.coupon_answer = item1.coupon_answer // 标准答案
                        tmp.coupon_id = item1.coupon_id // 优惠券索引
                        tmp.coupon_info = item1.coupon_info // 优惠券信息
                        return tmp
                    }
                    if (item1.score_operate === 2) { // 申请直赔
                        tmp.money = item1.money // 直赔金额
                        tmp.reason = item1.reason // 直赔原因
                        tmp.reason_value = item1.reason_value // 直赔原因
                        return tmp
                    }
                    if (item1.score_operate === 3) { // 发送商品
                        tmp.good_id = item1.good_id // 商品id
                        tmp.good_info = item1.good_info
                        return tmp
                    }
                    if (item1.score_operate === 4) { // 发送新建优惠券
                        tmp.coupon_id = item1.coupon_id // 优惠券类型
                        tmp.coupon_type = item1.coupon_type // 优惠券类型
                        tmp.good_id = item1.good_id // 商品id
                        tmp.good_info = item1.good_info
                        tmp.price_jian = item1.price_jian // 减
                        tmp.price_man = item1.price_man // 满
                        tmp.validity_time = item1.validity_time //有效期
                        tmp.validity_time_value = item1.validity_time_value //有效期
                        return tmp
                    }
                    if (item1.score_operate === 5) { // 发送核对订单
                        return tmp
                    }
                }
            })
            let stuPostContentTmp = {
                isLastOrRight: false, // 是否最后一次提问
                currentRobotDataIndex: 0, // 当前机器人数据
                stu_key_word: '', // 学生答案
                stu_post_time: 0, // 学生答题时间
                robot_ask_time: dayjs(), // 机器人提问时间
                stu_answer_time: '', // 学生答题时间

                analysis: robotItem.question[0].analysis,
                name: robotItem.question[0].name, // 考点 testing_name
                question: robotItem.question[0].question, // 问题 question
                score: robotItem.question[0].score, // 分值 score
                score_operate: robotItem.question[0].score_operate, // 得分操作
                type: robotItem.question[0].type, // 问题类型（1：问答题；2：操作题）
            }
            if (robotItem.question[0].type === 1) { // 问答题
                stuPostContentTmp.no_score_keyword = robotItem.question[0].no_score_keyword // 不得分关键词 no_key_word
                stuPostContentTmp.answer = robotItem.question[0].answer // 答案 model_answer
            } else if (robotItem.question[0].type === 2) {
                stuPostContentTmp.score_operate_value = robotItem.question[0].score_operate_value
                if (robotItem.question[0].score_operate === 1) { // 发送商品优惠券
                    stuPostContentTmp.coupon_answer = robotItem.question[0].coupon_answer // 标准答案
                    stuPostContentTmp.coupon_id = robotItem.question[0].coupon_id // 优惠券索引
                    stuPostContentTmp.coupon_info = robotItem.question[0].coupon_info // 优惠券信息
                }
                if (robotItem.question[0].score_operate === 2) { // 申请直赔
                    stuPostContentTmp.money = robotItem.question[0].money // 直赔金额
                    stuPostContentTmp.reason = robotItem.question[0].reason // 直赔原因
                    stuPostContentTmp.reason_value = robotItem.question[0].reason_value // 直赔原因
                }
                if (robotItem.question[0].score_operate === 3) { // 发送商品
                    stuPostContentTmp.good_id = robotItem.question[0].good_id // 商品id
                    stuPostContentTmp.good_info = robotItem.question[0].good_info
                }
                if (robotItem.question[0].score_operate === 4) { // 发送新建优惠券
                    stuPostContentTmp.coupon_id = robotItem.question[0].coupon_id // 优惠券类型
                    stuPostContentTmp.coupon_type = robotItem.question[0].coupon_type // 优惠券类型
                    stuPostContentTmp.good_id = robotItem.question[0].good_id // 商品id
                    stuPostContentTmp.good_info = robotItem.question[0].good_info
                    stuPostContentTmp.price_jian = robotItem.question[0].price_jian // 减
                    stuPostContentTmp.price_man = robotItem.question[0].price_man // 满
                    stuPostContentTmp.validity_time = robotItem.question[0].validity_time //有效期
                    stuPostContentTmp.validity_time_value = robotItem.question[0].validity_time_value //有效期
                }
            }

            let robotItemTmp = {
                id: robotItem.id,
                robot_id: robotItem.id,
                avatar: require('../../../../assets/images/student/avatar-online.png'),
                name: robotItem.name, // 机器人名称 robot_name
                question: arrTmp, // 问题 robot_data
                waitRobotProblem: null, // 等待机器人提问
                status: true, //机器人 上线：1  下线： 0
                waitTime: 0, // 机器人等待响应计时 看看是不是可删
                read: indexTmp === 0, // 消息已读未读
                stu_post_content: [stuPostContentTmp], // 学生与机器人问答内容

                good_id: robotItem.good_id, // 商品ID
                good_info: robotItem.good_info, // 商品信息
                is_have: robotItem.is_have, //是否已购买(1：是，0：否)
            }
            if (robotItem.is_have === 1) {
                robotItemTmp.order_sn = robotItem.order_sn // 订单编号
                robotItemTmp.recipients = robotItem.recipients // 收件人
                robotItemTmp.address = robotItem.address // 收货地址
                robotItemTmp.contact = robotItem.contact // 联系方式
            }
            return robotItemTmp
        },
        // 获取问题抛出的时间，超过等待时间，机器人下线并提交所有已回答的问题
        getTime(event, index, robotItem) {
            if (event > this.tmpTrain.wait_time) {
                this.robotList[index].status = false
                this.$refs[`robot_${robotItem.robot_id}`][0].endTime()
                let submitObjTmp = {
                    robot_id: robotItem.robot_id
                }
                let answerArrTmp = robotItem.stu_post_content.filter(item => {
                    return item.isLastOrRight === true
                })
                for (let i = answerArrTmp.length; i < robotItem.question.length; i++) {
                    answerArrTmp.push(robotItem.question[i])
                }
                submitObjTmp.content = answerArrTmp.map(item => {
                    return {
                        question: item.question,
                        stu_key_word: item.stu_key_word || '',
                        stu_post_time: item.stu_post_time || 0,
                        robot_ask_time: item.robot_ask_time ? item.robot_ask_time.format('YYYY-MM-DD HH:mm:ss') : '',
                        stu_answer_time: item.stu_answer_time ? item.stu_answer_time.format('YYYY-MM-DD HH:mm:ss') : '',
                    }
                })
                this.submitSingleRobot(submitObjTmp)
            }
        },
        // 切换当前对话框
        toggleTalking(id, type, index, robotItem) {
            this.robotCurrentId = id
            if (type === 1) {
                this.robotCurrentIndex = index
                robotItem.read = true
            } else if (type === 2) {
                let indexTmp = this.robotList.findIndex(item => {
                    return item.robot_id === id
                })
                if (indexTmp === -1) {
                    robotItem.add = true // 设置历史机器人超时未作答
                    this.robotCurrentIndex = this.robotList.push(robotItem) - 1
                } else {
                    this.robotCurrentIndex = indexTmp
                }
            }
        },
        // 发送消息
        sendMessage(stuPostContent) {
            if (this.isExamOrTrain) {
                let end_time = Number(localStorage.getItem('end_time'));
                let time = Date.parse(new Date()) / 1000;
                if ((end_time - time) < 0) {
                    this.$message.error('考试已经结束');
                    return;
                }
            }
            // if (this.userStatus !== 'online') {
            //     return this.$message.warning('请先把状态改为“接待”')
            // }
            // if (this.examStatus !== 2) {
            //     return this.$message.warning('请先开始考试')
            // }
            if (this.examing !== 1) {
                return this.$message.warning('请先开始考试')
            }
            if (this.chatHistoryCurrent) {
                if (!this.chatHistoryCurrent.status) {
                    return this.$message.warning('机器人已下线，不能再回答问题')
                }
                if (this.chatHistoryCurrent.waitRobotProblem) {
                    return this.$message.warning('等待机器人提问')
                }
            }
            if (this.tmpTrain.scene) {
                if (this.showScene) {
                    return this.$message.warning('请先确定当前场景')
                }
            }
            let currentRobotTmp = this.robotList[this.robotCurrentIndex]
            let currentTmp = this.robotList[this.robotCurrentIndex].stu_post_content // 当前机器人的对话列表
            let lengthTmp = currentTmp.length // 当前机器人对话列表的长度
            let currentChatTmp = currentTmp[lengthTmp - 1] // 当前此刻的对话
            let questionLength = this.robotList[this.robotCurrentIndex].question.length // 当前机器人的问题长度

            let editor = tinyMCE.get('tiny_item2');
            if (currentChatTmp.type === 1 && editor.getContent({ format: 'text' }) === '') {
                return this.$message.warning('请输入您的答案')
            }
            if (currentChatTmp.type === 2 && !stuPostContent && editor.getContent({ format: 'text' }) === '') {
                return this.$message.warning('请输入您的答案或者发送操作题答案')
            }

            let nowTime = dayjs()
            let currentChatTmpArr = currentTmp.filter(item => item.currentRobotDataIndex === currentChatTmp.currentRobotDataIndex)
            if (currentChatTmp.currentRobotDataIndex === questionLength - 1 && this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].isLastOrRight) {
                this.$message.success('该机器人的问题已答完')
                return
            }

            this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_answer_time = nowTime
            this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_post_time = nowTime.unix() - currentChatTmp.robot_ask_time.unix()
            this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_key_word = editor.getContent() //快捷码输入的时候this.chatContent的值为快捷码而不是快捷码对应的个人术语
            this.$refs[`robot_${currentRobotTmp.robot_id}`][0].endTime()
            let nextTmp = null // 下一个问题
            // 答案是否包含关键词 包含则下一题，不包含则继续提问（次数为设置的repeat_ask_num）
            let isInclude = null
            if (currentChatTmp.type === 1) { // 问答题
                isInclude = currentChatTmp.score_operate.find(item => {
                    return this.chatContent.includes(item)
                })
            } else if (currentChatTmp.type === 2 && stuPostContent) { // 操作题
                if (currentChatTmp.score_operate === 1) { // 发送商品优惠券
                    if (stuPostContent.score_operate === 1 && currentChatTmp.coupon_id === stuPostContent.coupon_id) {
                        isInclude = true
                    }
                    this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_key_word = stuPostContent
                }
                if (currentChatTmp.score_operate === 2) { // 申请直赔
                    if (stuPostContent.score_operate === 2 && stuPostContent.reason === currentChatTmp.reason && stuPostContent.money === currentChatTmp.money) {
                        isInclude = true
                    }
                    this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_key_word = stuPostContent
                }
                if (currentChatTmp.score_operate === 3) { // 发送商品
                    if (stuPostContent.score_operate === 3 && currentChatTmp.good_id === stuPostContent.good_id) {
                        isInclude = true
                    }
                    this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_key_word = stuPostContent
                }
                if (currentChatTmp.score_operate === 4) { // 发送新建优惠券
                    if (stuPostContent.score_operate === 4 &&
                        stuPostContent.coupon_id === currentChatTmp.coupon_id &&
                        stuPostContent.good_id === currentChatTmp.good_id &&
                        stuPostContent.price_man === currentChatTmp.price_man &&
                        stuPostContent.price_jian === currentChatTmp.price_jian &&
                        stuPostContent.validity_time === currentChatTmp.validity_time) {
                        isInclude = true
                    }
                    this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_key_word = stuPostContent
                }
                if (currentChatTmp.score_operate === 5) { // 核对订单
                    if (stuPostContent.score_operate === 5) {
                        isInclude = true
                    }
                    this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1].stu_key_word = stuPostContent
                }
            }
            if (isInclude || currentChatTmpArr.length === this.tmpTrain.repeat_ask_num) {
                // 下一题
                this.$set(this.robotList[this.robotCurrentIndex].stu_post_content[lengthTmp - 1], 'isLastOrRight', true)
                nextTmp = {
                    ...this.robotList[this.robotCurrentIndex].question[currentChatTmp.currentRobotDataIndex + 1],
                    currentRobotDataIndex: currentChatTmp.currentRobotDataIndex + 1
                }
            } else {
                // 继续当前题目发问
                nextTmp = {
                    ...this.robotList[this.robotCurrentIndex].question[currentChatTmp.currentRobotDataIndex],
                    currentRobotDataIndex: currentChatTmp.currentRobotDataIndex
                }
            }
            if (nextTmp.question) {
                let currentIndex = this.robotCurrentIndex
                this.robotList[this.robotCurrentIndex].waitRobotProblem = setTimeout(() => {
                    currentTmp.push(this.getCurrentChat(nextTmp))
                    this.$nextTick(() => {
                        this.$refs['chatContent'].wrap.scrollTop = this.$refs['chatContent'].wrap.scrollHeight
                    })
                    this.$refs[`robot_${currentRobotTmp.robot_id}`][0].startTime()
                    currentRobotTmp.waitRobotProblem = null
                    currentRobotTmp.read = false
                }, 1000)
            } else {
                // this.$message.success('该机器人的问题已答完')
                this.robotList[this.robotCurrentIndex].status = false
                let submitObjTmp = {
                    robot_id: this.robotList[this.robotCurrentIndex].robot_id
                }
                let answerArrTmp = currentTmp.filter(item => {
                    return item.isLastOrRight === true
                })
                submitObjTmp.content = answerArrTmp.map(item => {
                    return {
                        question: item.question,
                        stu_key_word: item.stu_key_word,
                        stu_post_time: item.stu_post_time,
                        robot_ask_time: item.robot_ask_time.format('YYYY-MM-DD HH:mm:ss'),
                        stu_answer_time: item.stu_answer_time.format('YYYY-MM-DD HH:mm:ss'),
                    }
                })
                // 用户回答完一个机器人 则提交一次
                this.submitSingleRobot(submitObjTmp)
            }
            this.chatContent = ''
        },
        // 获取下一个提问的问题内容
        getCurrentChat(chatItem) {
            let stuPostContentTmp = {
                isLastOrRight: false, // 是否最后一次提问
                currentRobotDataIndex: chatItem.currentRobotDataIndex, // 当前机器人数据
                stu_key_word: '', // 学生答案
                stu_post_time: 0, // 学生答题时间
                robot_ask_time: dayjs(), // 机器人提问时间
                stu_answer_time: '', // 学生答题时间

                analysis: chatItem.analysis,
                name: chatItem.name, // 考点 testing_name
                question: chatItem.question, // 问题 question
                score: chatItem.score, // 分值 score
                score_operate: chatItem.score_operate, // 得分操作
                type: chatItem.type, // 问题类型（1：问答题；2：操作题）
            }
            if (chatItem.type === 1) { // 问答题
                stuPostContentTmp.no_score_keyword = chatItem.no_score_keyword // 不得分关键词 no_key_word
                stuPostContentTmp.answer = chatItem.answer // 答案 model_answer
            } else if (chatItem.type === 2) {
                stuPostContentTmp.score_operate_value = chatItem.score_operate_value
                if (chatItem.score_operate === 1) { // 发送商品优惠券
                    stuPostContentTmp.coupon_answer = chatItem.coupon_answer // 标准答案
                    stuPostContentTmp.coupon_id = chatItem.coupon_id // 优惠券索引
                    stuPostContentTmp.coupon_info = chatItem.coupon_info // 优惠券信息
                }
                if (chatItem.score_operate === 2) { // 申请直赔
                    stuPostContentTmp.money = chatItem.money // 直赔金额
                    stuPostContentTmp.reason = chatItem.reason // 直赔原因
                    stuPostContentTmp.reason_value = chatItem.reason_value // 直赔原因
                }
                if (chatItem.score_operate === 3) { // 发送商品
                    stuPostContentTmp.good_id = chatItem.good_id // 商品id
                    stuPostContentTmp.good_info = chatItem.good_info
                }
                if (chatItem.score_operate === 4) { // 发送新建优惠券
                    stuPostContentTmp.coupon_id = chatItem.coupon_id // 优惠券类型
                    stuPostContentTmp.coupon_type = chatItem.coupon_type // 优惠券类型
                    stuPostContentTmp.good_id = chatItem.good_id // 商品id
                    stuPostContentTmp.good_info = chatItem.good_info
                    stuPostContentTmp.price_jian = chatItem.price_jian // 减
                    stuPostContentTmp.price_man = chatItem.price_man // 满
                    stuPostContentTmp.validity_time = chatItem.validity_time //有效期
                    stuPostContentTmp.validity_time_value = chatItem.validity_time_value //有效期
                }
            }
            return stuPostContentTmp
        },
        // 提交单个机器人答案
        submitSingleRobot(submitObj) {
            let formData = new FormData()
            formData.append('robot_id', submitObj.robot_id)
            formData.append('content', JSON.stringify(submitObj.content))
            if (this.isExamOrTrain) {
                kefuSubmit(formData).then((res) => {
                    this.$message.success(res.msg)
                })
            } else {
                formData.append('id', this.trainId)
                formData.append('course_id', this.course_id)
                formData.append('chapter', this.chapter)
                formData.append('node', this.node)
                kefu_drillSubmit(formData).then((res) => {
                    this.$message.success(res.msg)
                })
            }
        },
        // 考试提交并返回主界面
        submitExamBtn() {
            if (this.examStatus === 0) {
                return this.$message.warning('请开始考试')
            }
            if (this.examStatus === 2 && this.isExamOrTrain) {
                this.$message({
                    type: 'success',
                    message: '客服答题已结束',
                    duration: 1000,
                    onClose() {
                        window.close()
                    }
                })
                return
                // this.$router.push('/student/examing/onlineShopCustomerService/index')
            }
            if (this.tmpTrain.scene) {
                if (this.showScene) {
                    return this.$message.warning('还未答题')
                }
            }
            let findTmp = this.robotList.find(item => {
                let lengthTmp = item.stu_post_content.filter(item1 => {
                    return item1.isLastOrRight === true
                })
                return lengthTmp.length < item.question.length
            })
            this.$confirm(`${findTmp ? '当前客服机器人还未完成答题，确定退出？退出后记得再次进入完成所有客服机器人的答题' : '是否提交试卷？'}`, '提示消息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 获取在线的并且有答题的机器人
                let onlineRobotArr = this.robotList.filter(item => {
                    let hasAsked = item.stu_post_content.find(item1 => {
                        return item1.isLastOrRight === true
                    })
                    return item.status && hasAsked
                })
                // 过滤出status为true的机器人 并且 计算历史机器人的个数 相加是否为总的机器人个数
                let p1 = new Promise((resolve, reject) => {
                    let statusTrue = this.robotList.filter(item => {
                        return item.status === true
                    })
                    resolve(statusTrue)
                })
                let p2 = new Promise((resolve, reject) => {
                    if (this.isExamOrTrain) {
                        kefuHistorical().then((res) => {
                            resolve(res)
                        }).catch(error => {
                            reject(error)
                        })
                    } else {
                        let params = {
                            id: this.trainId,
                            course_id: this.course_id,
                            chapter: this.chapter,
                            node: this.node,
                        }
                        kefu_drillHistorical(params).then((res) => {
                            resolve(res)
                        }).catch(error => {
                            reject(error)
                        })
                    }
                })
                Promise.all([p1, p2]).then((result) => {
                    if (Object.prototype.toString.call(result[1].data) === '[object Object]') {
                        this.robotHistoryNum = result[1].data.robot_info.length
                    } else {
                        this.robotHistoryNum = 0
                    }
                    let statusTrueLength = result[0].length
                    if (statusTrueLength + this.robotHistoryNum === this.robotNum) {
                        // this.beginExam(2)
                    }
                }).catch((error) => {
                    console.log('error', error)
                })

                let submitData = [];
                if (onlineRobotArr.length > 0) {
                    onlineRobotArr.forEach(robotItem => {
                        robotItem.status = false
                        this.$refs[`robot_${robotItem.robot_id}`][0].endTime()
                        let submitObjTmp = {
                            robot_id: robotItem.robot_id
                        }
                        let answerArrTmp = robotItem.stu_post_content.filter(item => {
                            return item.isLastOrRight === true
                        })
                        for (let i = answerArrTmp.length; i < robotItem.question.length; i++) {
                            answerArrTmp.push(robotItem.question[i])
                        }
                        submitObjTmp.content = answerArrTmp.map(item => {
                            return {
                                question: item.question,
                                stu_key_word: item.stu_key_word || '',
                                stu_post_time: item.stu_post_time || 0,
                                robot_ask_time: item.robot_ask_time ? item.robot_ask_time.format('YYYY-MM-DD HH:mm:ss') : '',
                                stu_answer_time: item.stu_answer_time ? item.stu_answer_time.format('YYYY-MM-DD HH:mm:ss') : '',
                            }
                        })
                        submitData.push(submitObjTmp)
                    })
                }
                if (submitData.length > 0) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(submitData))
                    if (this.isExamOrTrain) {
                        kefuSubmitEven(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.robotList.forEach(robotItem => {
                                robotItem.status = false
                                // todo：没有找到  this.$refs[`robot_${robotItem.robot_id}`]
                                this.$refs[`robot_${robotItem.robot_id}`][0].endTime()
                                clearInterval(this.addRobotTimer)
                            })
                            // this.$router.push('/student/exam')
                        })
                    } else {
                        formData.append('id', this.trainId)
                        formData.append('course_id', this.course_id)
                        formData.append('chapter', this.chapter)
                        formData.append('node', this.node)
                        kefu_drillSubmitEven(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.robotList.forEach(robotItem => {
                                robotItem.status = false
                                this.$refs[`robot_${robotItem.robot_id}`][0].endTime()
                                clearInterval(this.addRobotTimer)
                            })
                        })
                    }
                // } else {
                //     this.$message.warning('请先回答机器人问题')
                }
                if (!this.isExamOrTrain) {
                    this.getTrainResult()
                } else {
                    this.$message({
                        type: 'success',
                        message: '提交成功',
                        duration: 1000,
                        onClose() {
                            window.close()
                        }
                    })
                    //新窗口打开，关闭当前页
                    // window.close()
                    // this.$router.push('/student/examing/onlineShopCustomerService/index');
                }
            }).catch(() => {
                this.$message.info('已取消')
            });
        },
        // 删除机器人
        delRobot(robotIndex, robotItem) {
            if (this.robotList.length > 1) {
                this.robotCurrentId = this.robotList[0].robot_id
                this.robotCurrentIndex = 0
            } else {
                this.robotCurrentId = null
                this.robotCurrentIndex = null
            }
            this.robotList.splice(robotIndex, 1)
        },
        // 训练返回主界面
        goBackTrain() {
            this.$router.push('/student/trainCenter/practice')
        },
        // 查看成绩
        getTrainResult() {
            let params = {
                id: this.trainId,
                course_id: this.course_id,
                chapter: this.chapter,
                node: this.node,
            }
            kefu_drillTrainingRecord(params).then((res) => {
                this.$message.success(res.msg)
                this.scoreContent = res.data
            })
        },

        // S 对话参考
        // 切换对话参考和培训成绩
        toggleDialogueScore(type) {
            this.showDialogueScore = type
            if (type === 1) {
                this.showDialogueContent = !this.showDialogueContent
            } else if (type === 2) {
                if (!this.isExamOrTrain) {
                    this.getTrainResult()
                }
            }
        },
        // E 对话参考

        // S 历史机器人
        historyListFormat(historyInfo) {
            let objTmp = {}
            historyInfo.robot_info.forEach(robotItem => {
                objTmp = {
                    id: robotItem.id,
                    robot_id: robotItem.id,
                    avatar: require('../../../../assets/images/student/avatar-online.png'),
                    name: robotItem.name, // 机器人名称 robot_name
                    question: [], // 处理 问题 robot_data
                    waitRobotProblem: null,
                    status: false, // 机器人已答完都下线
                    waitTime: 0, // 看看是不是可删 等待时间
                    read: true, // 消息已读
                    stu_post_content: [], // 处理

                    good_id: robotItem.good_id, // 商品ID
                    good_info: robotItem.good_info, // 商品信息
                    is_have: robotItem.is_have, //是否已购买(1：是，0：否)
                }
                if (robotItem.is_have === 1) {
                    objTmp.order_sn = robotItem.order_sn // 订单编号
                    objTmp.recipients = robotItem.recipients // 收件人
                    objTmp.address = robotItem.address // 收货地址
                    objTmp.contact = robotItem.contact // 联系方式
                }
                robotItem.question.forEach((item, index) => {
                    let questionTmp = {
                        analysis: item.analysis,
                        name: item.name,
                        question: item.question,
                        score: item.score,
                        score_operate: item.score_operate,
                        type: item.type,
                    }
                    if (item.type === 1) { // 问答题
                        questionTmp.no_score_keyword = item.no_score_keyword // 不得分关键词 no_key_word
                        questionTmp.answer = item.answer // 答案 model_answer
                    } else if (item.type === 2) {
                        questionTmp.score_operate_value = item.score_operate_value
                        if (item.score_operate === 1) { // 发送商品优惠券
                            questionTmp.coupon_answer = item.coupon_answer // 标准答案
                            questionTmp.coupon_id = item.coupon_id // 优惠券索引
                            questionTmp.coupon_info = item.coupon_info // 优惠券信息
                        }
                        if (item.score_operate === 2) { // 申请直赔
                            questionTmp.money = item.money // 直赔金额
                            questionTmp.reason = item.reason // 直赔原因
                            questionTmp.reason_value = item.reason_value // 直赔原因
                        }
                        if (item.score_operate === 3) { // 发送商品
                            questionTmp.good_id = item.good_id // 商品id
                            questionTmp.good_info = item.good_info
                        }
                        if (item.score_operate === 4) { // 发送新建优惠券
                            questionTmp.coupon_id = item.coupon_id // 优惠券类型
                            questionTmp.coupon_type = item.coupon_type // 优惠券类型
                            questionTmp.good_id = item.good_id // 商品id
                            questionTmp.good_info = item.good_info
                            questionTmp.price_jian = item.price_jian // 减
                            questionTmp.price_man = item.price_man // 满
                            questionTmp.validity_time = item.validity_time //有效期
                            questionTmp.validity_time_value = item.validity_time_value //有效期
                        }
                        if (item.score_operate === 5) { // 发送核对订单

                        }
                    }
                    objTmp.question.push(questionTmp)
                    let answerTmp = {
                        isLastOrRight: true,
                        currentRobotDataIndex: index,
                        stu_key_word: item.stu_answer.stu_key_word, // 学生答案 stu_key_word
                        stu_post_time: item.stu_answer.stu_post_time,
                        robot_ask_time: item.stu_answer.robot_ask_time ? dayjs(item.robot_ask_time) : '',
                        stu_answer_time: item.stu_answer.stu_answer_time ? dayjs(item.stu_answer_time) : '',

                        analysis: item.analysis,
                        name: item.name, // 考点 testing_name
                        question: item.question,
                        score: item.score,
                        score_operate: item.score_operate, // 得分操作
                        type: item.type, // 问题类型（1：问答题；2：操作题）
                    }
                    if (item.type === 1) { // 问答题
                        answerTmp.no_score_keyword = item.no_score_keyword // 不得分关键词 no_key_word
                        answerTmp.answer = item.answer // 答案 model_answer
                    } else if (item.type === 2) {
                        answerTmp.score_operate_value = item.score_operate_value
                        if (item.score_operate === 1) { // 发送商品优惠券
                            answerTmp.coupon_answer = item.coupon_answer // 标准答案
                            answerTmp.coupon_id = item.coupon_id // 优惠券索引
                            answerTmp.coupon_info = item.coupon_info // 优惠券信息
                        }
                        if (item.score_operate === 2) { // 申请直赔
                            answerTmp.money = item.money // 直赔金额
                            answerTmp.reason = item.reason // 直赔原因
                            answerTmp.reason_value = item.reason_value // 直赔原因
                        }
                        if (item.score_operate === 3) { // 发送商品
                            answerTmp.good_id = item.good_id // 商品id
                            answerTmp.good_info = item.good_info
                        }
                        if (item.score_operate === 4) { // 发送新建优惠券
                            answerTmp.coupon_id = item.coupon_id // 优惠券类型
                            answerTmp.coupon_type = item.coupon_type // 优惠券类型
                            answerTmp.good_id = item.good_id // 商品id
                            answerTmp.good_info = item.good_info
                            answerTmp.price_jian = item.price_jian // 减
                            answerTmp.price_man = item.price_man // 满
                            answerTmp.validity_time = item.validity_time //有效期
                            answerTmp.validity_time_value = item.validity_time_value //有效期
                        }
                    }
                    objTmp.stu_post_content.push(answerTmp)
                })
                this.historyList.push(objTmp)
            })
        },
        // 获取历史机器人
        getHistoryList() {
            if (this.isExamOrTrain) {
                kefuHistorical().then((res) => {
                    this.historyListFormat(res.data)
                }).catch((err) => {})
            } else {
                let params = {
                    id: this.trainId,
                    course_id: this.course_id,
                    chapter: this.chapter,
                    node: this.node,
                }
                kefu_drillHistorical(params).then((res) => {
                    this.historyListFormat(res.data)
                }).catch((err) => {})
            }
        },
        // E 历史机器人

        // 切换机器人咨询和客服助手
        toggleRobotOrCustomer(type) {
            this.robotOrCustomer = type
        },
        // S 机器人咨询
        toggleRobotConsult(type) {
            this.robotConsultStatus = type
        },
        // E 机器人咨询


        //  聊天添加表情
        addEmojiToChat(item) {
            let ed = tinyMCE.get('tiny_item2'); //获取编辑器实例
            ed.execCommand('mceInsertContent', false, `<img style='width: 24px; height: 24px' src='${item.src}'/>`)
            this.showChatEmoji = false
        },
        // 关闭场景
        closeScene() {
            this.showScene = false
            this.init()
        },
        clearSearchUser() {
            this.showOnlineOrSearch = 1
            // this.contactsSearch = [];
        },
        // 搜索用户
        searchUserBtn() {
            this.showOnlineOrSearch = 2
            // if (this.searchUser.trim().length) {
            //     searchName(this.searchUser.trim()).then((res) => {
            //         if (res.code === 200) {
            //             this.contactsSearch = res.data;
            //         } else {
            //             this.$message.warning(res.msg);
            //         }
            //     })
            // }
        },
        // 点击其他地方关闭发送方式弹窗
        hideSendMethods() {
            this.showSendMethodsBox = false
        },
        // 切换发送方式的弹窗
        toggleSendMethodBox() {
            this.showSendMethodsBox = true
        },
        // 选择发送方式
        toggleSendMethod(type) {
            this.sendMethods = type
            this.showSendMethodsBox = false
        },
    }
}