<template>
    <el-scrollbar class="right-content">
        <div class="dialogue-item"
             v-for="(dialogueItem, dialogueIndex) in dialogueList"
             :key="`stu_post_content_${dialogueIndex}`">
            <div class="question">客户问题：{{dialogueItem.question}}</div>
            <div class="answer-box" v-if="dialogueItem.isLastOrRight">
                <div class="model-answer" v-if="dialogueItem.type === 1">
                    <div class="title">标准答案:</div>
                    <div class="answer">{{dialogueItem.answer}}</div>
                </div>
                <div class="my-answer">
                    <div class="title">
                        <template v-if="dialogueItem.type === 1">
                            <i class="iconfont" :class="getAnswerRightOrError(dialogueItem.score_operate, dialogueItem.stu_key_word) ? 'green' : 'yellow'">
                                {{getAnswerRightOrError(dialogueItem.score_operate, dialogueItem.stu_key_word) ? '&#xe656;' : '&#xe659;'}}
                            </i>
                        </template>
                        <template v-else>
                            <template v-if="Object.prototype.toString.call(dialogueItem.stu_key_word) === '[object Object]'">
                                <template v-if="dialogueItem.score_operate === 1">
                                    <i class="iconfont"
                                       :class="dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                       && dialogueItem.stu_key_word.coupon_id === dialogueItem.coupon_id ? 'green' : 'yellow'">

                                        {{dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                        && dialogueItem.stu_key_word.coupon_id === dialogueItem.coupon_id ? '&#xe656;' : '&#xe659;'}}
                                    </i>
                                </template>
                                <template v-if="dialogueItem.score_operate === 2">
                                    <i class="iconfont"
                                       :class="dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                       && dialogueItem.stu_key_word.money === dialogueItem.money
                                       && dialogueItem.stu_key_word.reason === dialogueItem.reason  ? 'green' : 'yellow'">

                                        {{dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                        && dialogueItem.stu_key_word.money === dialogueItem.money
                                        && dialogueItem.stu_key_word.reason === dialogueItem.reason ? '&#xe656;' : '&#xe659;'}}
                                    </i>
                                </template>
                                <template v-if="dialogueItem.score_operate === 3">
                                    <i class="iconfont"
                                       :class="dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                       && dialogueItem.stu_key_word.good_id === dialogueItem.good_id ? 'green' : 'yellow'">

                                        {{dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                        && dialogueItem.stu_key_word.good_id === dialogueItem.good_id ? '&#xe656;' : '&#xe659;'}}
                                    </i>
                                </template>
                                <template v-if="dialogueItem.score_operate === 4">
                                    <i class="iconfont"
                                       :class="dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                       && dialogueItem.stu_key_word.coupon_id === dialogueItem.coupon_id
                                       && dialogueItem.stu_key_word.good_id === dialogueItem.good_id
                                       && dialogueItem.stu_key_word.price_man === dialogueItem.price_man
                                       && dialogueItem.stu_key_word.price_jian === dialogueItem.price_jian
                                       && dialogueItem.stu_key_word.validity_time === dialogueItem.validity_time ? 'green' : 'yellow'">

                                        {{dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate
                                        && dialogueItem.stu_key_word.coupon_id === dialogueItem.coupon_id
                                        && dialogueItem.stu_key_word.good_id === dialogueItem.good_id
                                        && dialogueItem.stu_key_word.price_man === dialogueItem.price_man
                                        && dialogueItem.stu_key_word.price_jian === dialogueItem.price_jian
                                        && dialogueItem.stu_key_word.validity_time === dialogueItem.validity_time ? '&#xe656;' : '&#xe659;'}}
                                    </i>
                                </template>
                                <template v-if="dialogueItem.score_operate === 5">
                                    <i class="iconfont"
                                       :class="dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate ? 'green' : 'yellow'">

                                        {{dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate ? '&#xe656;' : '&#xe659;'}}
                                    </i>
                                </template>
<!--                                <i class="iconfont" :class="dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate ? 'green' : 'yellow'">-->
<!--                                    {{dialogueItem.stu_key_word.score_operate === dialogueItem.score_operate ? '&#xe656;' : '&#xe659;'}}-->
<!--                                </i>-->
                            </template>
                            <template v-else>
                                <i class="iconfont yellow">&#xe659;</i>
                            </template>
                        </template>
                        <span>我的回答</span>
                    </div>
                    <template v-if="Object.prototype.toString.call(dialogueItem.stu_key_word) === '[object Object]'">
                        <div style="margin: 6px 0 0 18px; color: #0C6AFF; background: #DEEBFF; display: inline-block; padding: 0 6px; border-radius: 2px;">{{dialogueItem.stu_key_word.score_operate === 1 ? '发送商品优惠券'
                            : dialogueItem.stu_key_word.score_operate === 2 ? '申请直赔'
                            : dialogueItem.stu_key_word.score_operate === 3 ? '发送商品'
                            : dialogueItem.stu_key_word.score_operate === 4 ? '发送新建优惠券' : '发送核对订单'}}</div>
                    </template>
                    <div v-else class="answer" v-html="dialogueItem.stu_key_word ? dialogueItem.stu_key_word : '暂无回答'"></div>
                </div>
            </div>
            <div class="testing-centre">
                <div class="centre-item">
                    <div class="left">考点名称:</div>
                    <div class="right">{{dialogueItem.name}}</div>
                </div>
                <div class="centre-item" style="margin-top: 5px">
                    <div class="left" style="margin-top: 5px">{{dialogueItem.type === 1 ? '关键词' : '得分操作'}}:</div>
                    <div class="right tag-list">
                        <template v-if="dialogueItem.type === 1">
                            <div class="tag" v-for="(keyItem, keyIndex) in dialogueItem.score_operate"
                                 :key="`tagList_${dialogueIndex}_${keyIndex}`">{{keyItem}}
                            </div>
                        </template>
                        <template v-else>
                            <div class="tag">{{dialogueItem.score_operate_value}}</div>
                        </template>
                    </div>
                </div>
                <div class="centre-item">
                    <div class="left">解析:</div>
                    <div class="right">{{dialogueItem.analysis}}</div>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "DialoguePage",
        props: {
            dialogueList: {
                type: Array,
                default:() => []
            }
        },
        methods: {
            // 获取对话是否答对
            getAnswerRightOrError(keywordArr, answer) {
                return keywordArr.find(item => {
                    return answer.includes(item)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .right-content {
        flex: 1;
        height: 1%;
        padding: 10px 0 0;
        & > ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__wrap {

            }
        }
        .dialogue-item {
            font-size: 12px;
            position: relative;
            margin-bottom: 10px;
            padding: 0 20px 20px 10px;
            &:after {
                content: '';
                position: absolute;
                left: 10px;
                right: 20px;
                bottom: 0;
                border-bottom: 1px solid #D2D2D2;
            }
            &:last-child {
                margin-bottom: 0;
                &:after {
                    content: none;
                }
            }
            .question {

            }
            .answer-box {
                padding: 10px;
                margin-top: 10px;
                border: 1px solid #eee;
                .model-answer {
                    padding-bottom: 8px;
                    border-bottom: 1px solid #eee;
                    .answer {
                        margin-top: 4px;
                        color: #666;
                    }
                }
                .my-answer {
                    padding-top: 8px;
                    .title {
                        .iconfont {
                            font-size: 12px;
                            margin-right: 6px;
                        }
                    }
                    .answer {
                        color: #666;
                        margin-top: 4px;
                        padding-left: 18px;
                    }
                }
            }
            .testing-centre {
                color: #666;
                padding: 10px;
                margin-top: 10px;
                background: #eee;
                .centre-item {
                    display: flex;
                    margin-top: 10px;
                    &:first-child {
                        margin-top: 0;
                    }
                    .left {
                        width: 54px;
                        text-align: right;
                    }
                    .right {
                        flex: 1;
                        width: 1%;
                        margin-left: 5px;
                    }
                    .tag-list {
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: 0;
                        .tag {
                            color: #0C6AFF;
                            background: #DEEBFF;
                            border-radius: 2px;
                            padding: 0 6px;
                            line-height: 20px;
                            margin-top: 5px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
    .red {
        color: #F61F03;
    }
    .green {
        color: #00DF69;
    }
    .yellow {
        color: #FFB000;
    }
</style>