<template>
    <div style="height: 1%; flex: 1">
        <el-scrollbar class="customerOrder" v-if="chatHistoryCurrent">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm" :hide-required-asterisk="true" size="small">
                <el-form-item label="直赔原因" prop="reason">
                    <el-select v-model="ruleForm.reason" placeholder="请选择活动区域" style="width: 100%">
                        <el-option v-for="item in compensateList" :key="`compensateList_${item.id}`" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="直赔金额" prop="money">
                    <el-input-number class="input-number" v-model="ruleForm.money" :min="0.01" :precision="2" label="描述文字" :controls="false" style="width: 85px;"></el-input-number>
                    <span style="font-size: 12px; color: #666; margin-left: 6px">元，赔付至消费者账户余额中</span>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" maxlength="300" show-word-limit :rows="4" v-model="ruleForm.remark"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button type="success" :disabled="ruleForm.reason === null || ruleForm.money === void 0" @click="submitForm('ruleForm')">申请直赔</el-button>
                </el-form-item>
            </el-form>

            <div class="address-info">
                <div class="address-top">直赔记录</div>
                <!--            <template v-for="item in orderCompensateList">-->
                <div class="address-content" v-for="(item, index) in orderCompensateList" :key="`orderCompensateList_${index}`">
                    <div class="line">
                        <div class="name">直赔原因</div>：
                        <div class="detail">
                            {{item.stu_key_word.reason === 1 ? '运费补偿' : item.stu_key_word.reason === 2 ? '收货后少件补偿' : item.stu_key_word.reason === 3 ? '商品质量问题补偿'
                            : item.stu_key_word.reason === 4 ? '商品描述差异问题补偿' : item.stu_key_word.reason === 5 ? '其他类补偿' : item.stu_key_word.reason === 6 ? '活动返现类补偿'
                            : item.stu_key_word.reason === 7 ? '维修退换货瑕疵类补偿' : item.stu_key_word.reason === 8 ? '运转不及时补偿' : '未完成送装服务补偿'}}</div>
                    </div>
                    <div class="line">
                        <div class="name">直赔金额</div>：
                        <div class="detail">{{item.stu_key_word.money}}元</div>
                    </div>
                    <div class="line" v-if="item.stu_key_word.hasOwnProperty('remark')">
                        <div class="name">备 注</div>：
                        <div class="detail">{{item.stu_key_word.remark}}</div>
                    </div>
                </div>
                <!--            </template>-->
                <div class="no-data" v-if="orderCompensateList.length === 0">
                    <img class="no-img" :src="require('../../../assets/images/student/no-data.png')" alt="">
                    <p class="text">暂无数据</p>
                </div>
            </div>
        </el-scrollbar>
        <div class="no-data" v-else style="height: 100%">
            <img class="no-img" :src="require('../../../assets/images/student/no-data.png')" alt="">
            <p class="text">暂无数据</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            chatHistoryCurrent: {
                type: Object,
                default:() => {}
            },
        },
        data() {
            return {
                ruleForm: {
                    reason: null,
                    money: void 0,
                    remark: '',
                },
                rules: {
                    reason: [
                        { required: true, message: '请选择直赔原因', trigger: 'change' },
                    ],
                    money: [
                        { required: true, message: '请直赔金额', trigger: 'blur' }
                    ],
                },
                // 直赔原因
                compensateList: [
                    {
                        name: '运费补偿',
                        id: 1
                    },
                    {
                        name: '收货后少件补偿',
                        id: 2
                    },
                    {
                        name: '商品质量问题补偿',
                        id: 3
                    },
                    {
                        name: '商品描述差异问题补偿',
                        id: 4
                    },
                    {
                        name: '其他类补偿',
                        id: 5
                    },
                    {
                        name: '活动返现类补偿',
                        id: 6
                    },
                    {
                        name: '维修退换货瑕疵类补偿',
                        id: 7
                    },
                    {
                        name: '运转不及时补偿',
                        id: 8
                    },
                    {
                        name: '未完成送装服务补偿',
                        id: 9
                    },
                ],
                orderCompensateList: [],
            }
        },
        watch: {
            'chatHistoryCurrent.stu_post_content': {
                handler: function() {
                    let tmp = this.chatHistoryCurrent.stu_post_content.filter(item => {
                        return Object.prototype.toString.call(item.stu_key_word) === '[object Object]' && item.stu_key_word.score_operate === 2
                    })
                    this.orderCompensateList = tmp
                },
                deep: true,
            }
        },
        methods: {
            // 订单直赔
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let tmp = {
                            score_operate: 2,
                            reason: this.ruleForm.reason,
                            money: this.ruleForm.money,
                        }
                        if (this.ruleForm.remark) {
                            tmp.remark = this.ruleForm.remark
                        }
                        let typeTmp = this.chatHistoryCurrent.stu_post_content[this.chatHistoryCurrent.stu_post_content.length - 1]
                        if (typeTmp.type === 1) {
                            return this.$message.warning('当前题型为问答题，请输入答案')
                        } else {
                            this.$emit('sendMessage', tmp)
                            this.$message.success('申请直赔成功')
                        }
                        this.resetForm()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm() {
                this.$refs.ruleForm.resetFields();
                this.ruleForm = {
                    reason: null,
                    money: void 0,
                    remark: '',
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    p {
        margin: 0;
    }
    .customerOrder {
        /*height: 1%;*/
        /*flex: 1;*/
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .demo-ruleForm {
        margin-top: 20px;
        margin-right: 20px;
    }
    .input-number {
        ::v-deep .el-input__inner {
            text-align: left;
        }
    }

    .address-info {
        margin-top: 30px;
        border-bottom: 1px solid #E5E5E5;
        .address-top {
            height: 40px;
            background: #F5F8FA;
            line-height: 40px;
            padding-left: 20px;
        }
        .address-content {
            border-top: 1px solid #E5E5E5;
            padding: 12px;
            .line {
                display: flex;
                margin-bottom: 14px;
                &:last-child {
                    margin-bottom: 0;
                }
                .name {
                    width: 60px;
                    text-align-last: justify;
                }
                .detail {
                    width: 1%;
                    flex: 1;
                    span {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .no-img {
            max-width: 200px;
        }
        .text {
            color: #999;
            margin-top: 12px;
            padding: 0 20px;
        }
    }
</style>