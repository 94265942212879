<template>
    <div style="height: 1%; flex: 1">
        <el-scrollbar class="customerOrder" v-if="chatHistoryCurrent">
            <div class="robot-info">
                <div class="robot-avatar">
                    <img :class="chatHistoryCurrent.status ? 'current' : ''" :src="chatHistoryCurrent.avatar" alt="">
                </div>
                <div class="info-right">{{chatHistoryCurrent.name}} {{chatHistoryCurrent.status ? '[在线咨询]' : '[离线]'}}</div>
            </div>
            <div class="goods-info">
                <div class="good-img">
                    <img :src="chatHistoryCurrent.good_info.good_images" alt="" @click="viewGoodsView(chatHistoryCurrent.good_info)">
                </div>
                <div class="info-right">
                    <div class="line">
                        <p @click="viewGoodsView(chatHistoryCurrent.good_info)" class="goods-title">{{chatHistoryCurrent.good_info.good_title}}</p>
                        <i class="iconfont copy-sku" @click="clipSku(chatHistoryCurrent.good_info.good_title)">&#xe636;</i>
                    </div>
                    <div class="line">
                        单价 <span class="red">&yen;{{chatHistoryCurrent.good_info.sales_price}}</span>
                    </div>
                    <div class="line">
                        SKU: <span class="golden">{{chatHistoryCurrent.good_info.good_sku}}</span> <i class="iconfont copy-sku" @click="clipSku(chatHistoryCurrent.good_info.good_sku)">&#xe636;</i>
                    </div>
                </div>
            </div>
            <div class="coupons-list" v-if="couponsList.length > 0">
                <div class="coupon-item" v-for="(item, index) in couponsList" :key="`couponsList_${index}`">
                    <div class="item-left">
                        <div class="price-line">
                            &yen;<span>{{item.price_jian}}</span>
                        </div>
                        <p class="line">满{{item.price_man}}可用</p>
                    </div>
                    <div class="item-right">
                        <div class="line">
                            <span class="tags">{{item.type_name}}</span>
                            仅本店商品可用
                        </div>
                        <div class="line">{{item.start_time | formatDate}} - {{item.end_time | formatDate}}</div>
                    </div>
                    <el-button size="mini" class="btn-blue-shadow current" @click="sendCoupon(item, index)">发送</el-button>
                </div>
            </div>

            <div class="address-info" v-if="chatHistoryCurrent.is_have === 1">
                <div class="address-top">
                    <div class="top-left">
                        <span class="tag">待收货</span>
                        (在线支付){{chatHistoryCurrent.order_sn}}
                        <i class="iconfont copy-sku" @click="clipSku(chatHistoryCurrent.order_sn)">&#xe636;</i>
                    </div>
                    <i class="iconfont top-right">&#xe67b;</i>
                </div>
                <div class="address-content">
                    <div class="c-left">
                        <div class="line">
                            <div class="name">收货信息</div>：
                            <div class="detail">
                                {{chatHistoryCurrent.recipients}} {{chatHistoryCurrent.contact}} {{chatHistoryCurrent.address}}
                                <i class="iconfont copy-sku" @click="clipSku(`${chatHistoryCurrent.recipients} ${chatHistoryCurrent.contact} ${chatHistoryCurrent.address}`)">&#xe636;</i>
                            </div>
                        </div>
                        <div class="line">
                            <div class="name">数    量</div>：
                            <div class="detail">
                                1件<span>实付款：{{chatHistoryCurrent.good_info.sales_price}}元</span>
                            </div>
                        </div>
                    </div>
                    <div class="c-right">
                        <el-button size="mini" type="success" plain @click="checkOrderBtn">核对订单</el-button>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <div class="no-data" v-else>
            <img class="no-img" :src="require('../../../assets/images/student/no-data.png')" alt="">
            <p class="text">暂无数据</p>
        </div>
    </div>
</template>

<script>
    import ClipboardJS from "clipboard";
    import dayjs from 'dayjs'
    export default {
        filters: {
            formatDate(val) {
                return dayjs(val).format('YYYY-MM-DD')
            }
        },
        props: {
            chatHistoryCurrent: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                couponsList: []
            }
        },
        watch: {
            chatHistoryCurrent: {
                handler: function() {
                    if (this.chatHistoryCurrent) {
                        let tmp = this.chatHistoryCurrent.question.find(item => {
                            return item.type === 2 && item.score_operate === 1
                        })
                        if (tmp) {
                            this.couponsList = tmp.coupon_info
                        }
                    }
                },
                immediate: true,
            }
        },
        methods: {
            clipSku(value) {
                let clipboard = new ClipboardJS('.copy-sku', {
                    text(elem) {
                        return value;
                    }
                });
                clipboard.on('success', (e) => {
                    this.$message({
                        type: 'success',
                        message: '复制成功',
                        duration: 1000,
                        onClose: () => {
                            clipboard.destroy();
                        }
                    });
                });
            },
            // 发送商品优惠券
            sendCoupon(item, index) {
                let tmp = {
                    score_operate: 1,
                    coupon_id: index,
                    coupon_info: item
                }
                let typeTmp = this.chatHistoryCurrent.stu_post_content[this.chatHistoryCurrent.stu_post_content.length - 1]
                if (typeTmp.type === 1) {
                    return this.$message.warning('当前题型为问答题，请输入答案')
                } else {
                    this.$emit('sendMessage', tmp)
                    this.$message.success('发送商品优惠券成功')
                }
            },
            // 核对订单
            checkOrderBtn() {
                let tmp = {
                    score_operate: 5,
                    goodInfo: this.chatHistoryCurrent.good_info,
                    order_sn: this.chatHistoryCurrent.order_sn,
                    recipients: this.chatHistoryCurrent.recipients,
                    address: this.chatHistoryCurrent.address,
                    contact: this.chatHistoryCurrent.contact,
                }
                let typeTmp = this.chatHistoryCurrent.stu_post_content[this.chatHistoryCurrent.stu_post_content.length - 1]
                if (typeTmp.type === 1) {
                    return this.$message.warning('当前题型为问答题，请输入答案')
                } else {
                    this.$emit('sendMessage', tmp)
                    this.$message.success('核对订单成功')
                }
            },
            //查看商品详情
            viewGoodsView(item) {
                let routeDate = this.$router.resolve({
                    path: '/goods/detail',
                    query:{
                        g_id: item.id,
                        type: 1
                    }
                })
                window.open(routeDate.href, '_blank')
            }
        }
    }
</script>

<style scoped lang="scss">
    p {
        margin: 0;
    }
    .customerOrder {
        /*height: 1%;*/
        /*flex: 1;*/
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .robot-info {
        display: flex;
        align-items: center;
        height: 70px;
        padding-left: 15px;
        .robot-avatar {
            width: 40px;
            height: 40px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            img {
                max-width: 100%;
                filter: grayscale(100%);
                &.current {
                    filter: grayscale(0);
                }
            }
        }
        .info-right {
            margin-left: 10px;
            width: 1%;
            flex: 1;
        }
    }
    .goods-info {
        display: flex;
        align-items: center;
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        padding: 10px 15px;
        .good-img {
            width: 80px;
            height: 80px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .info-right {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            font-size: 12px;
            .line {
                display: flex;
                margin-bottom: 8px;
                line-height: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
                p {
                    width: 90%;
                    line-height: 1.2;
                }
                .iconfont {
                    cursor: pointer;
                    width: 10%;
                    color: #7D7D7D;
                    text-align: right;
                    line-height: 1;
                }
                span {
                    margin-left: 6px;
                    font-size: 14px;
                }
            }
        }
    }
    .red {
        color: #F61F03;
    }
    .golden {
        color: #B67F00;
    }
    .coupons-list {
        .coupon-item {
            display: flex;
            margin: 10px 18px 0;
            position: relative;
            .btn-blue-shadow {
                position: absolute;
                right: 10px;
                bottom: 10px;
            }
            .item-left {
                width: 112px;
                height: 88px;
                background: url("../../../assets/images/student/coupon-bg.png") no-repeat;
                color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 1;
                .price-line {
                    margin-bottom: 15px;
                    span {
                        font-size: 24px;
                    }
                }
            }
            .item-right {
                border: 1px solid #E5E5E5;
                border-left: none;
                padding-left: 10px;
                width: 1%;
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .line {
                    .tags {
                        line-height: 22px;
                        padding: 0 6px;
                        color: #1979FF;
                        display: inline-block;
                        border-radius: 2px;
                        border: 1px solid #1979FF;
                    }
                    &:first-child {
                        margin-top: 12px;
                    }
                    &:last-child {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
    .address-info {
        margin-top: 30px;
        .address-top {
            height: 40px;
            background: #F5F8FA;
            display: flex;
            align-items: center;
            .top-left {
                width: 1%;
                flex: 1;
                .tag {
                    line-height: 40px;
                    display: inline-block;
                    width: 60px;
                    color: #fff;
                    text-align: center;
                    background: #1979FF;
                    margin-right: 5px;
                }
                .iconfont {
                    color: #7D7D7D;
                    margin-left: 8px;
                    cursor: pointer;
                }
            }
            .top-right {
                cursor: pointer;
                line-height: 40px;
                padding: 0 18px;
                text-align: center;
                color: #7D7D7D;
                font-size: 20px;
            }
        }
        .address-content {
            display: flex;
            align-items: center;
            border-top: 1px solid #E5E5E5;
            border-bottom: 1px solid #E5E5E5;
            .c-left {
                width: 1%;
                flex: 1;
                padding: 12px;
                border-right: 1px solid #E5E5E5;
                .line {
                    display: flex;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .name {
                        width: 60px;
                        text-align-last: justify;
                    }
                    .detail {
                        width: 1%;
                        flex: 1;
                        span {
                            margin-left: 20px;
                        }
                        .iconfont {
                            color: #7D7D7D;
                            margin-left: 10px;
                        }
                    }
                }
            }
            .c-right {
                padding: 0 5px;
            }
        }
    }
    .goods-title {
        cursor: pointer;
    }
    .no-data {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .no-img {
            max-width: 200px;
        }
        .text {
            color: #999;
            margin-top: 12px;
            padding: 0 20px;
        }
    }
</style>