<template>
    <span v-show="showTime">{{useTime}}s</span>
</template>

<script>
    export default {
        model: {
            prop: 'useTime',
            event: 'change'
        },
        props: {
            useTime: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                showTime: false,
                timer: null
            }
        },
        methods: {
            startTime() {
                this.showTime = true
                this.timer = setInterval(() => {
                    this.$emit('change', this.useTime + 1)
                }, 1000)
            },
            endTime() {
                clearInterval(this.timer)
                this.showTime = false
            },
            restartTime() {
                clearInterval(this.timer)
                this.$emit('change', 0)
                this.startTime()
            }
        }
    }
</script>

<style scoped>

</style>