<template>
    <div style="height: 1%; flex: 1">
        <div class="customerOrder" v-if="chatHistoryCurrent">
            <el-scrollbar class="form-wrapper">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm" :hide-required-asterisk="true" size="small">
                    <el-form-item label="类型" prop="coupon_id">
                        <el-radio-group v-model="ruleForm.coupon_id" class="font-normal">
                            <el-radio :label="1">商品优惠券</el-radio>
                            <el-radio :label="2">店铺优惠券</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="优惠">
                        <div class="flex">
                            <span style="margin-right: 4px">满</span>
                            <el-form-item prop="price_man" style="margin-bottom: 0">
                                <el-input-number class="input-number" v-model="ruleForm.price_man" :min="0.01" :precision="2" :controls="false" style="width: 80px;"></el-input-number>
                            </el-form-item>
                            <span style="margin: 0 4px">元，减</span>
                            <el-form-item prop="price_jian" style="margin-bottom: 0">
                                <el-input-number class="input-number" v-model="ruleForm.price_jian" :min="0.01" :precision="2" :controls="false" style="width: 80px;"></el-input-number>
                            </el-form-item>
                            <span style="margin-left: 4px">元</span>
                        </div>
                        <div style="font-size: 12px; color: #666; margin-top: 8px" v-if="ruleForm.price_jian && ruleForm.price_man">折扣率为{{(ruleForm.price_jian / ruleForm.price_man).toFixed(2)}}%</div>
                        <div style="font-size: 12px; color: #666; margin-top: 8px" v-else>折扣率为0%</div>
                    </el-form-item>
                    <el-form-item label="有效期" prop="validity_time" class="label-top">
                        <el-radio-group v-model="ruleForm.validity_time" class="font-normal">
                            <el-radio :label="1" style="margin-bottom: 10px">当日23:59:59前使用有效</el-radio>
                            <el-radio :label="2">次日23:59:59前使用有效</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="添加商品" prop="good_id">
                        <!--                    <el-input :value="currentGood ? currentGood.good_sku : ''" clearable style="width: 182px; margin-right: 10px"></el-input>-->
                        <el-input v-model="searchVal" clearable style="width: 182px; margin-right: 10px"></el-input>
                        <el-button @click="openGoodsList">搜索</el-button>
                    </el-form-item>
                    <div class="current-good-wrapper" v-if="currentGood">
                        <div class="top">已选1件商品</div>
                        <div class="current-good">
                            <div class="good-image">
                                <img :src="currentGood.good_images" alt="">
                            </div>
                            <div class="good-info">
                                <div class="line text-overflow-2">{{currentGood.good_title}}</div>
                                <div class="line" style="text-align: right">&yen;{{currentGood.sales_price}}</div>
                            </div>
                            <i class="iconfont" @click="delGood">&#xec7b;</i>
                        </div>
                    </div>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../assets/images/student/no-data.png')" alt="">
                        <p class="text">暂无添加商品</p>
                    </div>
                </el-form>
            </el-scrollbar>
            <div class="bottom-wrapper">
                <el-button type="success" size="small" @click="submitForm('ruleForm')"
                           :disabled="ruleForm.good_id === null || ruleForm.price_man === void 0 || ruleForm.price_jian === void 0">发券</el-button>
            </div>

            <el-dialog title="添加商品" :visible.sync="dialogVisible" width="600px" custom-class="dialog-student"
                       @close="closeDialog" @opened="openDialog">
                <div class="goods-wrapper">
                    <div class="search-wrapper">
                        <el-input v-model="searchVal" @clear="clearSearch" clearable size="small" style="width: 240px; margin-right: 10px"></el-input>
                        <el-button size="small" @click="searchBtn">搜索</el-button>
                    </div>
                    <el-scrollbar class="goods-list" v-if="goodsList.length > 0">
                        <el-radio-group v-model="chooseGood" class="font-normal">
                            <el-radio :label="item.id" class="good-item" v-for="item in goodsList" :key="`goodsList_${item.id}`">
                                <div class="good-image">
                                    <img :src="item.good_images" alt="">
                                </div>
                                <div class="good-info">
                                    <div class="line text-overflow-2">{{item.good_title}}</div>
                                    <div class="red">&yen;{{item.sales_price}}</div>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </el-scrollbar>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../assets/images/student/no-data.png')" alt="">
                        <p class="text">暂无数据</p>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                    <el-button size="small" type="success" @click="chooseGoodBtn">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="no-data" v-else style="height: 100%">
            <img class="no-img" :src="require('../../../assets/images/student/no-data.png')" alt="">
            <p class="text">暂无数据</p>
        </div>
    </div>
</template>

<script>
    import { kefuGood, kefu_drillGood } from '@/utils/apis.js'
    export default {
        props: {
            chatHistoryCurrent: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                ruleForm: {
                    coupon_id: 1,
                    good_id: null,
                    price_man: void 0,
                    price_jian: void 0,
                    validity_time: 1,
                },
                // good_sku: '',
                rules: {
                    good_id: [
                        { required: true, message: '请选择商品', trigger: 'blur' },
                    ],
                    price_man: [
                        { required: true, message: '请输入金额', trigger: 'blur' }
                    ],
                    price_jian: [
                        { required: true, message: '请输入金额', trigger: 'blur' }
                    ],
                },

                dialogVisible: false,
                searchVal: '',
                chooseGood: null,
                goodsList: [],
                isExamOrTrain: localStorage.getItem('examId'), // 有：考试， null：训练
                trainId: Number(this.$route.query.id) || null,
                goods_name: ''
            }
        },
        computed: {
            currentGood() {
                return this.goodsList.find(item => {
                    return item.id === this.chooseGood
                })
            }
        },
        methods: {
            openGoodsList() {
                this.dialogVisible = true
            },
            getList() {
                let params = {}
                if (this.searchVal) {
                    params.search = this.searchVal
                }
                if (this.isExamOrTrain) {
                    kefuGood(params).then((res) => {
                        this.goodsList = res.data
                    })
                } else {
                    params.id = this.trainId
                    kefu_drillGood(params).then((res) => {
                        this.goodsList = res.data
                    })
                }
            },
            searchBtn() {
                if (this.searchVal === '') {
                    return this.$message.warning('请输入商品关键词/SKU')
                }
                this.getList()
            },
            clearSearch() {
                this.getList()
            },
            // 发送新建优惠券
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let tmp = {
                            score_operate: 4,
                            good_id: this.ruleForm.good_id,
                            coupon_id: this.ruleForm.coupon_id,
                            price_man: this.ruleForm.price_man,
                            price_jian: this.ruleForm.price_jian,
                            validity_time: this.ruleForm.validity_time,
                        }
                        let typeTmp = this.chatHistoryCurrent.stu_post_content[this.chatHistoryCurrent.stu_post_content.length - 1]
                        if (typeTmp.type === 1) {
                            return this.$message.warning('当前题型为问答题，请输入答案')
                        } else {
                            this.$emit('sendMessage', tmp)
                            this.$message.success('发送新建优惠券成功')
                        }
                        this.resetForm()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            chooseGoodBtn() {
                if (this.chooseGood === null) {
                    return this.$message.warning('请选择商品')
                }
                let tmp = this.goodsList.find(item => {
                    return item.id === this.chooseGood
                })
                if (tmp) {
                    this.ruleForm.good_id = tmp.id
                    this.dialogVisible = false
                }
            },
            closeDialog() {
                // this.chooseGood = null
            },
            openDialog() {
                this.getList()
                // if (this.ruleForm.good_id) {
                //     this.chooseGood = this.ruleForm.good_id
                // }
            },
            delGood() {
                this.chooseGood = null
            },
            resetForm() {
                this.$refs.ruleForm.resetFields();
                this.ruleForm = {
                    coupon_id: 1,
                    good_id: null,
                    price_man: void 0,
                    price_jian: void 0,
                    validity_time: 1,
                }
                this.chooseGood = null
            }
        }
    }
</script>

<style scoped lang="scss">
    p {
        margin: 0;
    }
    .customerOrder {
        /*height: 1%;*/
        /*flex: 1;*/
        height: 100%;
        display: flex;
        flex-direction: column;;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .form-wrapper {
        height: 1%;
        flex: 1;
    }
    .bottom-wrapper {
        padding: 18px 0;
        text-align: center;
        border-top: 1px solid #E5E5E5;
    }
    .demo-ruleForm {
        margin-top: 20px;
        margin-right: 20px;
    }
    .label-top {
        ::v-deep .el-form-item__label {
            line-height: 1.2;
        }
    }
    .font-normal {
        .el-radio {
            color: #333;
            font-weight: normal;
        }
    }
    .no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .no-img {
            max-width: 200px;
        }
        .text {
            color: #999;
            margin-top: 12px;
            padding: 0 20px;
        }
    }
    .red {
        color: #F61F03;
    }
    .goods-wrapper {
        .goods-list {
            margin-top: 14px;
            height: 300px;
            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .el-radio-group {
                width: 100%;
            }
        }
        .good-item {
            display: flex;
            align-items: center;
            margin-right: 0;
            border: 1px solid #E5E5E5;
            padding: 10px;
            border-top: none;
            &:first-child {
                border-top: 1px solid #E5E5E5;
            }
            ::v-deep {
                .el-radio__label {
                    width: 1%;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    .good-image {
                        width: 80px;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        background: red;
                        img {
                            max-width: 100%;
                        }
                    }
                    .good-info {
                        width: 1%;
                        flex: 1;
                        margin-left: 10px;
                        color: #333;
                        .red {
                            margin-top: 18px;
                        }
                    }
                }
            }
        }
    }
    .current-good-wrapper {
        margin-top: 20px;
        .top {
            padding-left: 22px;
            color: #666;
        }
        .current-good {
            margin-top: 15px;
            padding: 10px 15px;
            background: #F3F3F3;
            display: flex;
            align-items: center;
            .good-image {
                width: 80px;
                height: 80px;
                display: flex;
                img {
                    margin: auto;
                    max-width: 100%;
                }
            }
            .good-info {
                margin: 0 10px;
                width: 1%;
                flex: 1;
                .line {
                    &:first-child {
                        margin-bottom: 10px;
                    }
                }
            }
            .iconfont {
                color: #666;
                cursor: pointer;
            }
        }
    }
    .input-number {
        ::v-deep .el-input__inner {
            text-align: left;
        }
    }
</style>