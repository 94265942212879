<template>
    <div class="phraseChat-container">
        <div class="phraseChat-header"></div>
        <div class="phraseChat-content">
            <div class="phrase-header">个人短语</div>
            <el-input placeholder="搜索关键词/快捷短语" size="small" v-model="searchPhrase" @clear="clearSearchPhrase"
                      class="search-box" clearable @keyup.enter.native="searchPhraseBtn">
                <i slot="prefix" class="el-input__icon el-icon-search" @click="searchPhraseBtn"></i>
            </el-input>
            <el-scrollbar class="phrase-content" v-if="phraseList.length > 0">
                <div class="phrase-item" v-for="(level1Item, level1Index) in phraseList" :key="`phraseList_${level1Index}`">
                    <div @contextmenu.prevent="openEditQuickTalkOperate(phraseList, level1Item, $event, level1Index, 1)">
                        <div class="levelOne" @click="togglePhraseOne(level1Item)">
                            <i class="levelOne-icon" :class="level1Item.status ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                            <div class="text-overflow levelOne-text" :title="level1Item.name">{{level1Item.name}}</div>
                            <span class="number">({{level1Item.children.length}}/100)</span>
                        </div>
                    </div>
                    <template v-if="level1Item.status">
                        <div class="levelTwo" @click="addPhraseToChat1(level2Item)" @contextmenu.prevent="openEditQuickTalkOperate(level1Item, level2Item, $event, level2Index, 2)"
                             v-for="(level2Item, level2Index) in level1Item.children" :key="`level2list_${level2Index}`">
                            <span class="number" v-if="level2Item.fast_coding" v-html="`[${level2Item.fast_coding}]`"></span>
                            <div class="content text-overflow" v-html="level2Item.html"></div>
                            <div class="hover-content" v-html="level2Item.html"></div>
                        </div>
                    </template>
                </div>
            </el-scrollbar>
            <div class="no-data" v-else>
                <img class="no-img" :src="require('../../../assets/images/student/no-data.png')" alt="">
                <p class="text">暂无个人短语</p>
            </div>
            <div class="phrase-bottom">
                <div class="left">
                    <el-link type="success" :underline="false" @click="openQuickTalkDialog">新建</el-link>
                    <el-link :underline="false" @click="exportPhrase">导出</el-link>
                    <el-link :underline="false" @click="importPhrase">导入</el-link>
                    <input type="file" ref="excelInput" accept=".xls, .xlsx" title="" @change="changeExcel($event)" style="display: none;">
                </div>
                <el-link type="success" :underline="false" href="kefu/download" >下载</el-link>
            </div>
        </div>

        <el-dialog title="快捷话术设置" :visible.sync="quickTalkDialog" width="600px"
                   custom-class="dialog-student" @close="closeQuickTalkDialog" @opened="openQuickTalk">
            <el-form :model="quickTalkForm" :rules="quickTalkRules" ref="quickTalkForm" label-width="100px" size="small" class="phrase-form">
                <div class="flex">
                    <el-form-item label="选择分组" prop="group_id">
                        <el-select v-model="quickTalkForm.group_id" style="width: 160px" class="select-gray" :disabled="quickTalkForm.quickTalkList.length === 0">
                            <el-option v-for="item in quickTalkForm.quickTalkList" :label="item.name" :value="item.id" :key="`group_${item.id}`"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="groupName" label-width="0">
                        <template  v-if="quickTalkForm.showAdd">
                            <el-input placeholder="输入组名称" class="input-gray" v-model="quickTalkForm.groupName" style="width: 160px; margin-left: 8px"></el-input>
                            <el-button class="btn-blue-stu" @click="addQuickTalkGroup" style="margin-left: 16px">添加</el-button>
                        </template>
                        <el-link v-else class="link-blue-shadow" :underline="false"
                                 style="margin-left: 10px; display: block"
                                 @click="quickTalkForm.showAdd = !quickTalkForm.showAdd">
                            <i class="el-icon-plus"></i>新建分组
                        </el-link>
                    </el-form-item>
                </div>
                <el-form-item label="添加话术" prop="content" class="detail-item">
                    <tiny-editor ref="tiny_item1" id="tiny_item1" :init="tiny_init" v-model="quickTalkForm.content"></tiny-editor>
                    <div v-clickoutside="hidePhraseEmoji">
                        <div class="tiny-bottom">
                            <i class="iconfont emoji-icon" @click="togglePhraseEmoji">&#xe681;</i>
                            <span class="box-right">{{quickTalkLength}}/1024</span>
                        </div>
                        <div class="emojiPack-wrapper" v-if="showPhraseEmoji">
                            <div class="emojiPack-list">
                                <div class="emojiPack-item" @click="addEmojiToPhrase(emojiItem)"
                                     v-for="(emojiItem, emojiIndex) in emojiList"
                                     :key="`dialog_${emojiIndex}`">
                                    <img :src="emojiItem.src" alt="">
                                </div>
                            </div>
                            <div class="emojiPack-bottom">
                                <div class="item">
                                    <img :src="require('./emoji/s31.png')" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item class="upload-quickTalk-wrapper">
                    <i v-if="quickTalkForm.img"
                       class="el-icon-error clear-quickTalk-img"
                       @click="clearQuickTaliImg"></i>
                    <div style="line-height: 1">可导入一张图片（5M以内）</div>
                    <el-upload class="img-uploader"
                               :action="uploadCover"
                               :headers="headersParams"
                               name="img"
                               accept=".jpg, .jpeg, .png"
                               :show-file-list="false"
                               :on-success="handleCoverSuccess"
                               :before-upload="beforeCoverUpload">
                        <img v-if="quickTalkForm.img" :src="quickTalkForm.img" class="quickTalk-img">
                        <div class="add-box" v-else>
                            <i class="el-icon-plus add-icon"></i>
                            <div class="add-text">上传图片</div>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="快捷编码" prop="fast_coding">
                    <el-input class="input-gray" v-model="quickTalkForm.fast_coding" @focus="autoAppend" placeholder="输入“/#”+关键词或快捷编码，可以进行快捷话术联想"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="btn-blue-shadow" size="small" @click="cancelQuickTalk">取 消</el-button>
                <el-button class="btn-blue-stu" size="small" @click="addQuickTalk('quickTalkForm')">确 定</el-button>
            </div>
        </el-dialog>

        <div class="operate-wrapper" ref="phraseLevel2Edit" v-show="showPhraseLevel2Edit && !searchPhrase" v-clickoutside="hidePhraseLevel2Edit">
            <div class="operate-item" @click="changeQuickTalk(phraseLevel2EditCurrentType)">修改</div>
            <div class="operate-item" @click="delQuickTalk(phraseLevel2EditCurrentType)">删除</div>
            <div class="operate-item" v-if="phraseLevel2EditCurrentIndex !== 0" @click="moveUpQuickTalk(phraseLevel2EditCurrentType, 'up')">上移</div>
            <template v-if="phraseLevel2EditCurrentType === 1">
                <template v-if="phraseLevel2EditCurrentParent">
                    <div class="operate-item" v-if="phraseLevel2EditCurrentParent.length !== phraseLevel2EditCurrentIndex + 1"
                         @click="moveUpQuickTalk(phraseLevel2EditCurrentType, 'down')">下移</div>
                </template>
            </template>
            <template v-else-if="phraseLevel2EditCurrentType === 2">
                <template v-if="phraseLevel2EditCurrentParent">
                    <div class="operate-item" v-if="phraseLevel2EditCurrentParent.children.length !== phraseLevel2EditCurrentIndex + 1"
                         @click="moveUpQuickTalk(phraseLevel2EditCurrentType, 'down')">下移</div>
                </template>
            </template>
        </div>

        <el-dialog ref="phraseGroupEdit" title="修改分组名称" :visible.sync="showPhraseGroupEdit" width="300px" :modal="false"
                   custom-class="dialog-gradient">
            <div class="edit-levelOne" v-if="phraseLevel2EditCurrent">
                <div class="edit-l-content">
                    <el-input placeholder="分组名称" class="group-name" size="small" v-model="phraseLevel2EditCurrent.name" clearable></el-input>
                </div>
                <div class="edit-l-bottom">
                    <el-button class="btn-blue-shadow" size="mini" @click="showPhraseGroupEdit = false">取消</el-button>
                    <el-button class="btn-blue-stu" @click="editPhraseGroupName" size="mini">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { termsList, groupList, termsSearch, termsImport, groupSave, groupDel, termsDel, groupOperate, termsOperate, termsSave, termsUpload, termsGet } from '@/utils/apis.js'
    import Editor from '@tinymce/tinymce-vue'
    import clickoutside from '../../../utils/clickoutside'
    import emoji from './js/emoji'

    export default {
        directives: {
            clickoutside
        },
        components: {
            'tiny-editor': Editor,
        },
        data() {
            let that = this
            let validateShortCode = (rule, value, callback) => {
                if (!(/^\/#\w+/.test(value))) {
                    return callback(new Error('快捷码必须是以“/#”开头的字符串'));
                }
                callback();
            };
            return {
                searchPhrase: '',
                phraseList: [],
                quickTalkDialog: false,
                quickTalkForm: {
                    id: '',
                    group_id: '',
                    showAdd: false,
                    quickTalkList: [],
                    groupName: '',
                    content: '',
                    img: '',
                    fast_coding: '',
                },
                quickTalkRules: {
                    group_id: [
                        {required: true, message: '请选择分组', trigger: 'change'}
                    ],
                    content: [
                        {required: true, message: '请添加话术', trigger: 'blur'},
                    ],
                    groupName: [
                        {message: '请输入分组名称', trigger: 'blur'},
                    ],
                    fast_coding: [
                        {required: true, message: '请输入快捷编码', trigger: 'blur'},
                        {validator: validateShortCode, trigger: 'blur'}
                    ]
                },
                // 快捷话术富文本框设置
                tiny_init: {
                    // base_url: '/tiny_mce/',
                    language: 'zh_CN',
                    menubar: false,
                    toolbar: '',
                    height: 150,
                    statusbar: false,
                    content_css: './style/phraseEmoji.css',
                    auto_focus: true,
                    setup: (editor) => {
                        editor.on('input', () => {
                            let myText = editor.getContent({format: 'text'})
                            that.quickTalkLength = myText.length
                        });
                    }
                },
                showPhraseEmoji: false,
                emojiList: emoji,
                // quickTalkImg: null,
                headersParams: {
                    Authorization: localStorage.getItem('studentToken')
                },
                uploadCover: termsUpload(),
                quickTalkLength: 0,
                showPhraseLevel2Edit: false,
                showPhraseGroupEdit: false,
                phraseLevel2EditCurrent: null,
                phraseLevel2EditCurrentParent: null,
                phraseLevel2EditCurrentIndex: null,
                phraseLevel2EditCurrentType: null,
                phraseLevel2EditCurrentEvent: null,
            }
        },
        mounted() {
            this.getPhraseList()
        },
        methods: {
            // S 聊天和短语
            // 切换聊天和短语
            // togglePhraseChat(type) {
            //     this.showPhraseChat = type
            // },
            // 获取个人短语列表
            getPhraseList() {
                termsList().then((res) => {
                    this.phraseList = this.getPhraseListFormat(res.data)
                })
            },
            // 获取个人短语分组
            getGroupList() {
                groupList().then((res) => {
                    this.quickTalkForm.quickTalkList = res.data
                }).catch(() => {})
            },
            // 调整短语列表格式
            getPhraseListFormat(list) {
                list.forEach(item => {
                    item.status = true
                    item.children.forEach((item1) => {
                        if (item1.img) {
                            item1.html = item1.content.replace(/<(?!img).*?>/g, '') + '[图片]'
                        } else {
                            item1.html = item1.content.replace(/<(?!img).*?>/g, '')
                        }
                        this.emojiList.forEach(item2 => {
                            if (item1.html.includes(item2.code)) {
                                item1.html = item1.html.replace(new RegExp(item2.code, 'g'), `<img style='width: 24px; height: 24px' src='${item2.src}'/>`)
                            }
                        })
                    })
                })
                return list
            },
            // 搜索个人短语
            searchPhraseBtn() {
                if (this.searchPhrase === '') {
                    this.getPhraseList()
                    return this.$message.warning('请输入关键词/快捷短语')
                }
                let params = {
                    search: this.searchPhrase
                }
                termsSearch(params).then((res) => {
                    let arrTmp = this.getPhraseListFormat(res.data)
                    arrTmp.forEach(item => {
                        item.children.forEach(item1 => {
                            item1.html = this.brightKeyword(item1.html)
                            item1.fast_coding = this.brightKeyword(item1.fast_coding)
                        })
                    })
                    this.phraseList = arrTmp
                })
            },
            brightKeyword(val) {
                let keyword = this.searchPhrase
                if (val) {
                    if (val.includes(keyword)) {
                        // todo：替换img标签内以外的内容
                        return val.replace(keyword, `<span style="color: red;">${keyword}</span>`)
                    } else {
                        return val
                    }
                }
            },
            clearSearchPhrase() {
                this.getPhraseList()
            },
            // 导出短语
            exportPhrase() {
                let tmp = this.phraseList.find(item => {
                    return item.children.length > 0
                })
                if (tmp) {
                    let idTmp = Number(localStorage.getItem('studentId'))
                    let url = localStorage.getItem('hosturl')
                    window.location.href = `${url}terms/export?id=${idTmp}`;
                } else {
                    return this.$message.warning('当前没有短语可导出')
                }
            },
            // 导入短语
            importPhrase() {
                this.$refs.excelInput.click();
            },
            // 导入短语
            changeExcel(event) {
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 10) {
                    this.$message.warning('文件大小不得超过10M');
                    return;
                }
                let fileList = event.target.files[0];
                let formData = new FormData();
                formData.append('excel', fileList);
                termsImport(formData).then((res) => {
                    this.$message.success(res.msg)
                    this.getPhraseList()
                }).finally(() => {
                    this.$refs.excelInput.value = ''
                })
            },
            // 修改短语组名
            editPhraseGroupName() {
                if (this.phraseLevel2EditCurrent.name === '') {
                    return this.$message.warning('分组名称不能为空')
                }
                let formData = new FormData()
                formData.append('id', this.phraseLevel2EditCurrent.id)
                formData.append('name', this.phraseLevel2EditCurrent.name)
                groupSave(formData).then((res) => {
                    this.$message.success(res.msg)
                    this.showPhraseGroupEdit = false
                    this.getPhraseList()
                })
            },
            // 切换短语一级显示隐藏
            togglePhraseOne(val) {
                val.status = !val.status
            },
            // 右键个人短语二级弹窗
            openEditQuickTalkOperate(parentList, item, event, index, type) {
                this.phraseLevel2EditCurrentParent = parentList
                this.phraseLevel2EditCurrent = JSON.parse(JSON.stringify(item))
                this.phraseLevel2EditCurrentIndex = index
                this.phraseLevel2EditCurrentType = type
                this.phraseLevel2EditCurrentEvent = event
                this.$refs['phraseLevel2Edit'].style.top = (event.clientY - 30) + 'px'
                this.$refs['phraseLevel2Edit'].style.left = (event.clientX + 20) + 'px'
                this.showPhraseLevel2Edit = true
            },
            hidePhraseLevel2Edit() {
                this.showPhraseLevel2Edit = false
            },
            // 修改短语
            changeQuickTalk(type) {
                if (type === 1) {
                    this.showPhraseGroupEdit = true
                    this.$refs['phraseGroupEdit'].$el.style = `top: ${this.phraseLevel2EditCurrentEvent.clientY - 74}px`
                } else {
                    let params = {
                        id: this.phraseLevel2EditCurrent.id,
                    }
                    termsGet(params).then((res) => {
                        this.quickTalkForm = {
                            id: res.data.id,
                            group_id: res.data.group_id,
                            showAdd: false,
                            quickTalkList: [],
                            groupName: '',
                            content: res.data.content,
                            img: res.data.img ? res.data.img : '',
                            fast_coding: res.data.fast_coding ? res.data.fast_coding : '',
                        }
                    })
                    this.quickTalkDialog = true
                }
                this.showPhraseLevel2Edit = false
            },
            // 删除二级短语
            delQuickTalk(type) {
                this.$confirm(`${type === 1 ? '是否删除此分组' : '是否删除此短语'}，删除后将无法恢复`, '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue-shadow current',
                    cancelButtonClass: 'btn-blue-shadow',
                    type: 'warning',
                }).then(() => {
                    if (type === 1) {
                        let params = {
                            id: this.phraseLevel2EditCurrent.id
                        }
                        groupDel(params).then((res) => {
                            this.$message.success(res.msg)
                            this.showPhraseLevel2Edit = false
                            this.getPhraseList()
                        })
                    }
                    if (type === 2) {
                        let params = {
                            id: this.phraseLevel2EditCurrent.id
                        }
                        termsDel(params).then((res) => {
                            this.$message.success(res.msg)
                            this.showPhraseLevel2Edit = false
                            this.getPhraseList()
                        })
                    }
                }).catch(() => {
                    this.$message.info('已取消')
                });
            },
            // 上移短语
            moveUpQuickTalk(type, sort) {
                let params = {
                    id: this.phraseLevel2EditCurrent.id
                }
                if (sort === 'up') {
                    params.order = 1
                } else {
                    params.order = 0
                }
                let parentTmp = null
                if (type === 1) {
                    parentTmp = this.phraseLevel2EditCurrentParent
                } else {
                    parentTmp = this.phraseLevel2EditCurrentParent.children
                }
                if (sort === 'up') {
                    parentTmp.splice(this.phraseLevel2EditCurrentIndex - 1, 0, this.phraseLevel2EditCurrent)
                    parentTmp.splice(this.phraseLevel2EditCurrentIndex + 1, 1)
                } else {
                    parentTmp.splice(this.phraseLevel2EditCurrentIndex + 2, 0, this.phraseLevel2EditCurrent)
                    parentTmp.splice(this.phraseLevel2EditCurrentIndex, 1)
                }
                this.$message.success('修改成功')
                if (type === 1) {
                    groupOperate(params).then((res) => {
                        // this.$message.success(res.msg)
                        // this.getPhraseList()
                    })
                }
                if (type === 2) {
                    termsOperate(params).then((res) => {
                        // this.$message.success(res.msg)
                        // this.getPhraseList()
                    })
                }
                this.showPhraseLevel2Edit = false
            },
            // 新建个人短语
            openQuickTalkDialog() {
                this.quickTalkDialog = true
            },
            // 关闭新建快捷话术弹窗
            closeQuickTalkDialog() {
                this.$refs.quickTalkForm.resetFields();
                this.quickTalkForm = {
                    id: '',
                    group_id: '',
                    showAdd: false,
                    quickTalkList: [],
                    groupName: '',
                    content: '',
                    img: '',
                    fast_coding: '',
                }
                // this.quickTalkImg = null
            },
            // 显示话术表情
            togglePhraseEmoji() {
                this.showPhraseEmoji = !this.showPhraseEmoji
            },
            // 添加个人短语分组
            addQuickTalkGroup() {
                if (this.quickTalkForm.groupName === '') {
                    return this.$message.warning('请输入分组名称')
                }
                let formData = new FormData()
                formData.append('name', this.quickTalkForm.groupName)
                groupSave(formData).then((res) => {
                    this.$message.success(res.msg)
                    this.getGroupList()
                    this.quickTalkForm.groupName = ''
                }).catch((err) => {
                })
            },
            // 话术添加表情
            addEmojiToPhrase(item) {
                let ed = tinyMCE.get('tiny_item1'); //获取编辑器实例
                ed.execCommand('mceInsertContent', false, `<img style='width: 24px; height: 24px' src='${item.src}'/>`)
                this.showPhraseEmoji = false
            },
            // 短语话术图片上传成功后
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    // this.quickTalkImg = res.data.src
                    this.quickTalkForm.img = res.data[0]
                    this.$message.success(res.msg)
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
            // 短语话术图片上传前
            beforeCoverUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 5;
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 5MB!');
                }
                return isLt2M;
            },
            clearQuickTaliImg() {
                // this.quickTalkImg = null
                this.quickTalkForm.img = ''
            },
            cancelQuickTalk() {
                this.quickTalkDialog = false
                this.getPhraseList()
            },
            autoAppend() {
                //第一次聚焦，自动添加‘/#’
                if (this.quickTalkForm.fast_coding.length === 0) {
                    this.quickTalkForm.fast_coding = '/#';
                }
            },
            // 添加话术短语
            addQuickTalk(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('group_id', this.quickTalkForm.group_id)
                        formData.append('content', this.quickTalkForm.content)
                        formData.append('fast_coding', this.quickTalkForm.fast_coding)
                        formData.append('img', this.quickTalkForm.img)
                        if (this.quickTalkForm.id) {
                            formData.append('id', this.quickTalkForm.id)
                        }
                        termsSave(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.getPhraseList()
                            this.quickTalkDialog = false
                        }).catch((err) => {})
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 切换全部聊天记录与图片记录
            // toggleAllChatHistory(type) {
            //     this.showAllChatHistory = type
            // },
            // 搜索聊天记录
            // searchChatHistoryBtn() {
            //     console.log('搜索聊天记录')
            // },

            // 打开新建话术弹窗
            openQuickTalk() {
                this.getGroupList()
                let ed = tinyMCE.get('tiny_item1');
                this.quickTalkLength = ed.getContent({format: 'text'}).length
            },
            hidePhraseEmoji() {
                this.showPhraseEmoji = false
            },
            // 点击短语自动添加到对话框
            addPhraseToChat1(item) {
                let ed = tinyMCE.get('tiny_item2');
                let text = null
                if (item.img) {
                    text = `${item.content.replace(/<(?!img).*?>/g, '')}<img style='max-width: 260px; max-height: 140px' src='${item.img}'/>`
                } else {
                    text = item.content.replace(/<(?!img).*?>/g, '')
                }
                this.emojiList.forEach(item2 => {
                    if (text.includes(item2.code)) {
                        text = text.replace(new RegExp(item2.code, 'g'), `<img style='width: 24px; height: 24px' src='${item2.src}'/>`)
                    }
                })
                ed.execCommand('mceInsertContent', false, text)
            },
            // E 聊天和短语
        }
    }
</script>

<style scoped lang="scss">
    @import "./style/index.scss";
</style>